




















// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ISwitch } from './';

@Component({
  name: 'Toggle'
})
class Toggle extends Vue {
  @Prop({ required: true }) public config!: ISwitch;
  @Prop({ required: true }) public value!: string | number;
  public created() {}
  public updateValue(value: boolean) {
    this.config.value = !this.config.value;
    this.$emit('input', this.config.value);
    if (this.config.onClick) {
      this.config.onClick();
    }
  }
}
export default Toggle;
