import { IDashboardTranslations } from '..';
import common from '../common/pt';

const dashboard: IDashboardTranslations = {
  ...common,
  welcome: 'Bem vindo ao beamian admin',
  whatsup: 'O que precisas fazer hoje?',
  create_event: 'Criar evento',
  create_event_description: 'Quero criar um novo evento',
  create_device: 'Criar dispositivo',
  create_device_description: 'Quero criar um novo dispositivo',
  events: 'Eventos',
  manage_events: 'Quero gerir eventos',
  devices: 'Dispositivos',
  manage_devices: 'Quero gerir dispositivos',
  categories: 'Categorias',
  manage_categories: 'Quero gerir categorias',
  models: 'Modelos',
  manage_models: 'Quero gerir modelos',
  download_subscribed_emails: 'Quero fazer download dos emails'

};

export default dashboard;
