




















// @ is an alias to /src
import { editEventSettings, getEventSettings } from '@/api/events';
import { ISelect } from '@/components/form';
import Select from '@/components/form/select.vue';
import Modal from '@/components/modal.vue';
import router from '@/router';
import { IStateEvent } from '@/store/modules/events';
import { IEventsTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { eventActionPopup, eventsModal } from './../';

@Component({
  name: 'LiveInteractions',
  components: { Modal, Select },
})
class LiveInteractions extends Vue {
  @Getter('getEvents') public events!: IStateEvent[];

  @Action('fetchEvents') public fetch!: () => void;

  @Getter('getEventsTranslations') public translations!: IEventsTranslations;

  @Getter('getSelectedEvents') public selected!: number[];
  public eventActionPopup = eventActionPopup;
  public liveInteractionsSelect: ISelect = {
    id: 'live-interactions-select',
    value: 'disabled',
    options: [],
  };
  public liveInteractions = '';
  public modal = eventsModal;
  public eventSettingsId = 0;
  public created() {
    this.liveInteractionsSelect.options = [
      {
        label: this.translations.enabled,
        value: 'enabled',
      },
      {
        label: this.translations.disabled,
        value: 'disabled',
      },
    ];
    getEventSettings(this.selected[0]).then((response) => {
      const liveInteractionsEnabled =
        response.data.results[0].live_interactions;
      this.eventSettingsId = response.data.results[0].id;
      if (liveInteractionsEnabled) {
        this.liveInteractionsSelect.value = 'enabled';
        this.liveInteractions = 'enabled';
      } else {
        this.liveInteractionsSelect.value = 'disabled';
        this.liveInteractions = 'disabled';
      }
    });
  }
  public submit() {
    const eventSettings = {
      id: this.eventSettingsId,
      live_interactions: this.liveInteractions === 'enabled',
    };

    editEventSettings(eventSettings)
      .then((response) => {
        this.eventActionPopup.isActive = true;
        this.eventActionPopup.message = `Live interactions ${this.liveInteractions}`;
        this.eventActionPopup.type = 'success';
        setTimeout(() => {
          this.eventActionPopup.isActive = false;
        }, 5000);
      })
      .catch((err) => {
        this.eventActionPopup.isActive = true;
        this.eventActionPopup.message = 'Live interactions failed to apply';
        this.eventActionPopup.type = 'danger';
        setTimeout(() => {
          this.eventActionPopup.isActive = false;
        }, 5000);
      });

    router.push('/events');
  }
  public discard() {
    router.push('/events');
  }
}

export default LiveInteractions;
