<template>
  <div class="field">
    <div class="field-label is-normal" v-if="config.label">
      <label class="label">{{ config.label }}</label>
    </div>
    <div class="select is-fullwidth is-loading" v-if="!optionsSet">
      <select>
        <option selected>{{ config.placeholder }}</option>
      </select>
    </div>
    <vSelect
      v-if="optionsSet"
      :options="options"
      :placeholder="config.placeholder"
      :multiple="config.multiple"
      @input="onChange"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: "VueSelect",
  components: {
    vSelect,
  },
  props: ["config"],
  data() {
    return { options: [], dataLoaded: false, optionsSet: false };
  },
  watch: {
    // whenever question changes, this function will run
    config: function (newConfig, oldConfig) {
      if (!this.dataLoaded) {
        this.dataLoaded = true;

        const options = newConfig.options.map((option) => ({
          code: option.value,
          label: option.label,
        }));

        this.options = options;
        this.optionsSet = true;
      }
    },
  },
  methods: {
    onChange(value) {
      this.$emit("input", value.code);
      this.config.error = false;
      if (this.config.onInput) {
        this.config.onInput(value.code);
      }
    },
  },
  created() {
    this.$emit("input", this.config.value);
    this.config.error = false;
    if (this.config.onInput) {
      this.config.onInput(value);
    }

    if (this.config.options.length > 0) {
      const options = this.config.options.map((option) => ({
        code: option.value,
        label: option.label,
      }));
      this.options = options;
      this.optionsSet = true;
    }
  },
};
</script>
<style lang="scss">
@import "vue-select.scss";
@import "./src/bulma/form/select";
.select.is-loading select {
  color: $grey;
}
</style>