
import { ISpacesTranslations } from '../';

const spaces: ISpacesTranslations = {
  name: 'Nome',
  spaces: 'Espacios',
  deleteSpaces: 'Eliminar espacios',
  currentCount: 'Recuento actual',
  capacity: 'Capacidad',
  slug: 'Prefijo URL',
  website: 'Website',
  facebook: 'Facebook URL',
  phone: 'Número de teléfono',
  delete_spaces_confirm: '¿Seguro que quieres eliminar estos espacios?',
  user: 'User',
  updated_at: 'Last update',
};

export default spaces;
