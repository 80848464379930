import { IAuthTranslations } from '..';

const auth: IAuthTranslations = {
  welcome: 'Bem vindo/a.',
  email: 'Email',
  email_placeholder: 'Digite o seu email',
  password: 'Palavra passe',
  password_placeholder: 'Digite a sua palavra passe',
  login: 'Entrar',
  log_back: 'Voltar a entrar?',
  logged_out: 'A sua sessão foi terminada',
  or: 'ou',
  other_account: 'Outra conta',
};

export default auth;
