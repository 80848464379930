import { IModal, IOffcanvas } from '@/components';
import { IInput, ITextarea } from '@/components/form';
import { ITable } from '@/components/table';
import router from '@/router';
import { IStateDeviceModel } from '@/store/modules/deviceModels';
import { IDeviceModelsTranslations } from '@/translations';

export const table: (translations: IDeviceModelsTranslations) => ITable<IStateDeviceModel> = translations => ({
  id: 'device-models-table',
  columns: [
    {
      key: 'name',
      label: translations.name
    },
    {
      key: 'description',
      label: translations.description
    },
    {
      key: '_category',
      childProp: 'name',
      label: translations.category
    }
  ],
  order: {
    name: 'desc'
  },
  actions: [{
    label: translations.edit,
    icon: 'edit',
    position: 'row',
    route: {
      name: '/devices/models/edit',
      param: 'id'
    }
  },
    {
      label: translations.delete,
      icon: 'delete',
      position: 'row',
      route: {
        name: '/devices/models/delete',
        param: 'id'
      }
    },

    {
      label: translations.create,
      position: 'top-right',
      icon: 'library_add',
      route: {
        name: '/devices/models/add'
      }
    },
    {
      label: translations.change_category,
      position: 'bulk',
      icon: 'category',
      route: {
        name: '/devices/models/change-category'
      }
    },
    {
      label: translations.delete,
      icon: 'delete',
      position: 'bulk',
      route: {
        name: '/devices/models/deletes'
      }
    }],
  visibleFields: {
    name: true,
    _category: true
  },
  searchFields: {
    name: true,
    _category: true
  },
  controls: true,
  insideModal: true,
  clickableRows: true,
  refreshAction: 'fetchDeviceModels',
  selectionGetter: 'getSelectedDeviceModels'
});

export interface IDeviceModelForm {
  name: IInput;
  description: ITextarea;
}
export const form: (translations: IDeviceModelsTranslations) => IDeviceModelForm = translations => ({
  name: {
    id: 'device-model-name',
    label: translations.device_model_name,
    type: 'text',
    placeholder: translations.device_model_name_placeholder,
    value: ''
  },
  description: {
    id: 'device-model-description',
    label: translations.device_model_description,
    placeholder: translations.device_model_description_placeholder,
    value: ''
  }
});

export const deviceModelModal: IModal = {
  isActive: true,
  close: () => {
    router.push('/devices/models');
  }
};

export const deviceModelOffcanvas: IOffcanvas = {
  isActive: true,
  close: () => {
    router.push('/devices/models');
  }
};

export const deviceModel: IStateDeviceModel = {
  name: '',
  description: '',
  loading: false,
  error: false,
  message: ''
};
