import { loginUser } from '@/api/auth';
import { IAuth } from '@/models/auth';
import { IUser } from '@/models/user';
import { ActionContext } from 'vuex';

import { IState } from '..';
import { IObjectState } from '../';

export interface IAuthState extends IObjectState {
  user: IUser | {};
  token: string;
  userLoggedIn: boolean;
}

const state: IAuthState = {
  user: {
    name: '',
    email: '',
  },
  token: '',
  userLoggedIn: false,
  loading: false,
  message: '',
  error: false
};

const getters = {
  userAuthState: (state: IAuthState) => ({
    userLoggedIn: state.userLoggedIn,
    token: state.token,
    loading: state.loading,
    message: state.message,
    error: state.error
  }),
  userToken: (state: IAuthState) => state.token,
  currentUser: (state: IAuthState) => state.user,
  userLoggedIn: (state: IAuthState) => state.userLoggedIn
};

const actions = {

  loginUser(
    context: ActionContext<IAuthState, IState>,
    user: IUser
  ) {

    context.commit('setUserLoading');

    const data = {
      username: user.email ? user.email : '',
      password: user.password ? user.password : ''
    };

    loginUser(data)
      .then(response => {
        if (response.status === 200) {
          const auth = {
            token: response.data.token,
            user
          };
          context.commit('setUserLogin', auth);
        } else {
          context.commit('setUserAuthError', 'User not found');
        }
      })
      .catch(err => {
        if (err.response.status === 400) {
          context.commit('setUserAuthError', 'Email or password are incorrect');
        } else {
          context.commit('setUserAuthError', err.message);
        }
      });

  },
};

const mutations = {

  setUserLoading: (state: IAuthState) => (
    (state.loading = true),
    (state.error = false)
  ),

  setUserLogin: (state: IAuthState, auth: IAuth) => (
    (state.userLoggedIn = true),
    (state.loading = false),
    (state.error = false),
    (state.token = auth.token),
    (state.user = auth.user)
  ),

  setUserAuthError: (state: IAuthState, message: string) => (
    (state.message = message),
    (state.error = true),
    (state.userLoggedIn = false),
    (state.loading = false)
  ),

  setUserLogout: (state: IAuthState) => (
    (state.error = false),
    (state.loading = false),
    (state.userLoggedIn = false)
  )

};

export default {
  state,
  getters,
  actions,
  mutations
};
