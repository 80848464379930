import { ICommonTranslations } from '..';

const common: ICommonTranslations = {
  dashboard: 'Painel de controlo',
  events: 'Eventos',
  devices: 'Dispositivos',
  logout: 'Sair',
  edit: 'Editar',
  create: 'Criar',
  delete: 'Apagar',
  name: 'Nome',
  email: 'Email',
  description: 'Descrição',
  category: 'Categoria',
  model: 'Modelo',
  save: 'Guardar',
  download: 'Descarregar',
  upload: 'Enviar',
  change: 'Alterar',
  apply: 'Aplicar',
  enabled: 'Ativo',
  disabled: 'Inativo',
  users: 'Utilizadores',
};

export default common;
