

























// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ITextarea } from './';

@Component({
  name: 'Textarea'
})
class Textarea extends Vue {
  @Prop({ required: true }) public config!: ITextarea;
  @Prop({ required: true }) public value!: string | number;
  public created() {}
  public updateValue(value: string | number) {
    this.$emit('input', value);
  }
}
export default Textarea;
