




















































































































































































































































// @ is an alias to /src
import {
  addBooklet,
  addBookletImage,
  editBooklet,
  getBooklet,
  getParticipatingFields,
  updateBookletImage,
} from '@/api/events';
import { IDragEvent, IDropdown, IModal } from '@/components';
import Draggable from '@/components/draggable.vue';
import Dropdown from '@/components/dropdown.vue';
import { ISelect } from '@/components/form';
import Checkbox from '@/components/form/checkbox.vue';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Modal from '@/components/modal.vue';
import Offcanvas from '@/components/offcanvas.vue';
import { IBooklet, IBrandField } from '@/models/event';
import router from '@/router';
import store from '@/store';
import { IStateEvent } from '@/store/modules/events';
import { IEventsTranslations } from '@/translations';
import modelToForm from '@/utils/modelToForm';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import {
  bookletFieldType,
  bookletForm,
  bookletIcons,
  dropdown,
  eventActionPopup,
  eventModel,
  eventsModal,
  eventsOffcanvas,
  IEventBookletForm,
} from './../';

type PartialField = Partial<IBrandField> & {
  icon?: { name: string; label: string; path: string };
};

export interface IFieldIcon {
  name: string;
  label: string;
  path: string;
}

@Component({
  name: 'Booklet',
  components: {
    Modal,
    Input,
    Checkbox,
    Select,
    Draggable,
    Offcanvas,
    Dropdown,
  },
})
class Booklet extends Vue {
  @Getter('getEvents') public events!: IStateEvent[];

  @Action('fetchEvents') public fetch!: () => void;

  @Getter('getEventsTranslations') public translations!: IEventsTranslations;

  @Getter('getSelectedEvents') public selected!: number[];
  public fieldTypes: string[] = [];
  public modal = { ...eventsModal, isLarge: true };
  public offcanvas = { ...eventsOffcanvas, isActive: false, close: null };
  public eventActionPopup = eventActionPopup;
  public bookletCoverImage = {} as File;
  public dropdown = dropdown;
  public fieldTypeSelect: ISelect[] = [];
  public bookletIcons = bookletIcons;
  public event = eventModel;
  public form!: IEventBookletForm;
  public bookletTitle = '';
  public booklet!: IBooklet;
  public bookletExists = false;
  public selectedField = 0;
  public fields: IBrandField[] = [];
  public selectedFields: PartialField[] = [];
  public loadingFields = true;
  public loadingSubmit = false;
  public id = 0;
  public created() {
    this.form = bookletForm(this.translations);

    if (this.selected.length === 1) {
      this.id = this.selected[0];
      if (this.events.length > 0) {
        this.findAndAssign();
      } else {
        this.fetch();
        const unsubscribe = store.subscribe((mutation: MutationPayload) => {
          if (mutation.type === 'setEvents') {
            this.findAndAssign();
            unsubscribe();
          }
        });
      }
    }
  }
  public selectIcon(field: PartialField, icon: IFieldIcon) {
    this.selectedFields = this.selectedFields.map((selectedField) => {
      if (selectedField === field) {
        selectedField.icon = icon;
      }
      return selectedField;
    });
  }
  public selectField(field: PartialField | undefined) {
    field = this.fields.find(
      (selectedField) => field && selectedField.id === field.id
    );
    if (field && field.id) {
      const index = this.selectedFields.indexOf(field);
      if (index === -1) {
        this.selectedFields.push(field);
      } else {
        this.selectedFields.splice(index, 1);
      }
      this.fieldTypeSelect[field.id] = {
        ...bookletFieldType,
      };
    }
  }
  public addEmptySpace() {
    this.selectedFields.push({
      id: this.selectedFields.length + 1,
      title: this.translations.empty_space,
      field_type: 'empty',
    });
  }
  public findAndAssign() {
    const event = this.events.find((event) => event.id === this.id);
    if (event && event.id) {
      this.event = event;

      getParticipatingFields(event.id)
        .then((response) => {
          this.fields = response.data.results;
          getBooklet(event.id).then((response) => {
            this.loadingFields = false;
            this.booklet = response.data.results[0];
            if (this.booklet && this.booklet.configuration) {
              this.bookletExists = true;

              if (this.booklet.configuration.answers) {
                this.booklet.configuration.answers.forEach((answer) => {
                  if (answer.blank) {
                    const field = {
                      id: answer.index,
                      title: this.translations.empty_space,
                      field_type: 'empty',
                    };
                    this.selectedFields.push({
                      ...field,
                      icon: bookletIcons.find(
                        (icon) => icon.name === answer.icon
                      ),
                    });
                  } else {
                    const field = this.fields.find(
                      (field) => field.id === answer.participating_field_id
                    );
                    if (field) {
                      this.fieldTypeSelect[field.id] = {
                        ...bookletFieldType,
                        value: answer.style ? answer.style : '',
                      };

                      this.selectedFields.push({
                        ...field,
                        icon: bookletIcons.find(
                          (icon) => icon.name === answer.icon
                        ),
                      });
                    }
                  }
                });
              }

              if (this.booklet.configuration.files) {
                this.booklet.configuration.files.forEach((file) => {
                  const field = this.fields.find(
                    (field) => field.id === file.participating_field_id
                  );
                  if (field) {
                    this.fieldTypeSelect[field.id] = {
                      ...bookletFieldType,
                      value: file.style ? file.style : '',
                    };
                    this.selectedFields.push(field);
                  }
                });
              }

              this.form.title = {
                ...this.form.title,
                value: this.booklet.configuration.cover_title.en,
              };

              this.bookletTitle = this.booklet.configuration.cover_title.en;
            }
          });
        })
        .catch((err) => {
          this.loadingFields = false;
        });
    }
  }
  public openFields() {
    this.offcanvas.isActive = true;
  }
  public onFieldMoved(event: IDragEvent) {
    const newIndex = event.moved.newIndex;
    const oldIndex = event.moved.oldIndex;
    if (newIndex >= this.selectedFields.length) {
      let k = newIndex - this.selectedFields.length + 1;
      while (k--) {
        this.selectedFields.push(undefined as any);
      }
    }
    this.selectedFields.splice(
      newIndex,
      0,
      this.selectedFields.splice(oldIndex, 1)[0]
    );
  }
  public removeField(selectedField: PartialField) {
    const field = this.selectedFields.find(
      (field) => field.id === selectedField.id
    );
    if (field) {
      const index = this.selectedFields.indexOf(field);
      this.selectedFields.splice(index, 1);
    }
  }
  public async submit() {
    if (this.event.id) {
      this.loadingSubmit = true;
      const booklet: IBooklet = {
        configuration: {
          answers: this.selectedFields
            .map((selectedField, index) => ({
              index,
              icon: selectedField.icon ? selectedField.icon.name : undefined,
              participating_field_id: selectedField.id,
              style: selectedField.id
                ? this.fieldTypes[selectedField.id]
                : undefined,
              blank: selectedField.field_type === 'empty' ? true : undefined,
            }))
            .filter(
              (selectedField) =>
                selectedField.style !== 'BookletFile' &&
                selectedField.style !== 'BookletBrochure' &&
                selectedField.style !== 'BookletLogo'
            ),
          files: this.selectedFields
            .map((selectedField, index) => ({
              index,
              type: 'logo',
              icon: selectedField.icon ? selectedField.icon.name : undefined,
              participating_field_id: selectedField.id ? selectedField.id : 0,
              style: selectedField.id
                ? this.fieldTypes[selectedField.id]
                : undefined,
            }))
            .filter(
              (selectedField) =>
                selectedField.style === 'BookletFile' ||
                selectedField.style === 'BookletBrochure' ||
                selectedField.style === 'BookletLogo'
            ),
          cover_title: {
            pt: this.bookletTitle,
            en: this.bookletTitle,
            es: this.bookletTitle,
          },
        },
        event: this.event.id,
      };

      if (this.bookletExists && this.booklet.id) {
        if (this.bookletCoverImage) {
          const imageForm = new FormData();
          const backgroundImageForm = new FormData();

          imageForm.append('cover_image', this.bookletCoverImage);
          addBookletImage(this.booklet.id, imageForm);
        } else {
          delete this.event.image;
        }
        editBooklet(this.booklet.id, booklet)
          .then((response) => {
            this.loadingSubmit = false;
            router.push('/events');

            this.triggerPopup({
              message: this.translations.edit_booklet_success,
              type: 'success',
            });
          })
          .catch((err) => {
            this.loadingSubmit = false;

            this.triggerPopup({
              message: this.translations.edit_booklet_error,
              type: 'danger',
            });
          });
      } else {
        addBooklet(booklet)
          .then((response) => {
            this.bookletExists = true;
            this.booklet = response.data;
            if (this.bookletCoverImage) {
              const imageForm = new FormData();
              const backgroundImageForm = new FormData();
              imageForm.append('cover_image', this.bookletCoverImage);

              if (this.booklet.id) {
                const bookletCover = {
                  event: this.event.id,
                  cover_image: imageForm,
                };
                addBookletImage(this.booklet.id, imageForm);
              }
            } else {
              delete this.event.image;
            }
            this.loadingSubmit = false;
            router.push('/events');

            this.triggerPopup({
              message: this.translations.create_booklet_success,
              type: 'success',
            });
          })
          .catch((err) => {
            this.loadingSubmit = false;

            this.triggerPopup({
              message: this.translations.create_booklet_error,
              type: 'danger',
            });
          });
      }
    }
  }
  public discard() {
    router.push('/events');
  }
  public triggerPopup({ message, type }: { message: string; type: string }) {
    this.eventActionPopup.isActive = true;
    this.eventActionPopup.message = message;
    this.eventActionPopup.type = type;
    setTimeout(() => {
      this.eventActionPopup.isActive = false;
    }, 5000);
  }
}

export default Booklet;
