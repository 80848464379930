
import { IUsersTranslations } from '../';

const users: IUsersTranslations = {
  name: 'Name',
  email: 'Email',
  users: 'Users',
  phone: 'Phone number',
  deleteUsers: 'Delete Users',
  delete_users_confirm: 'Are you sure you want to delete the selected users? This action cannot be undone.',
};

export default users;
