

























































































import Dropdown from '@/components/dropdown.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import { ICommonTranslations } from '../translations';

@Component({
  name: 'Navbar',
  components: { Dropdown },
})
class Navbar extends Vue {
  @Mutation('setLanguage') public setLanguage!: (lang: 'pt' | 'es' | 'en') => void;

  @Getter('getCommonTranslations') public translations!: ICommonTranslations;

  @Getter('getLanguage') public language!: 'es' | 'pt' | 'en';
  public created() {}
  public changeLanguage(lang: 'pt' | 'es' | 'en') {
    const localStorageLangProp = process.env.VUE_APP_LOCAL_STORAGE_LANG_PROP;
    localStorage.setItem(localStorageLangProp, lang);
    this.setLanguage(lang);
  }
}

export default Navbar;
