import { IUser } from '@/models/user';
import axios from 'axios';

import { Resolve } from './';
import config from './config';

interface IData {
  results: IUser[];
  item_count: number;
  page_count: number;
  current: number;
  next: number;
  previous: number;
}

const url = process.env.VUE_APP_SERVER_PATH;

export const fetchUsers = (page = 1, pageSize = 20, search = undefined as undefined | string) => new Promise((resolve: Resolve<IData>, reject) => {
  axios
    .get(`${url}/v2/beamian-users?page_size=${pageSize}&page=${page}${search ? `&search=${encodeURIComponent(search)}` : ''}`, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const editPassword = (newPassword: string, userEmail: string) => new Promise((resolve: Resolve<IData>, reject) => {
  const passwordChangeUrl = url.replace(/\/api([^/api]*)$/, '$1');

  axios
    .post(`${passwordChangeUrl}/accounts/change-password`, { new_password: newPassword, user_email: userEmail }, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const createUser = (user: IUser) =>
  new Promise((resolve: Resolve<IUser>, reject) => {
    axios
      .post(`${url}/v2/beamian-users`, user, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const fetchUserByEmail = (email: string) => new Promise((resolve: Resolve<IData>, reject) => {
  axios
    .get(`${url}/v2/beamian-users?email=${encodeURIComponent(email)}`, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const fetchUserById = (id: number) => new Promise((resolve: Resolve<IUser>, reject) => {
  axios
    .get(`${url}/v2/beamian-users/${id}`, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const editUser = (user: IUser) => new Promise((resolve: Resolve<IData>, reject) => {
  axios
    .patch(`${url}/v2/beamian-users/${user.id}`, user, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const deleteUser = (userId: number) => new Promise((resolve: Resolve<any>, reject) => {
  axios
    .delete(`${url}/v2/beamian-users/${userId}`, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});
