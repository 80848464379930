import { IDropdown, IModal, IOffcanvas, IPopup } from '@/components';
import { IInput } from '@/components/form';
import { ITable } from '@/components/table_v2';
import router from '@/router';
import { IStateSpace } from '@/store/modules/spaces';
import { ISpacesTranslations } from '@/translations';

export const table: (translations: ISpacesTranslations) => ITable<IStateSpace> = translations => ({

  id: 'spaces-table',
  columns: [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'name',
      label: translations.name
    },
    {
      key: 'current',
      label: translations.currentCount
    },
    {
      key: 'capacity',
      label: translations.capacity
    },
    {
      key: 'slug',
      label: translations.slug
    },
    {
      key: 'website',
      label: translations.website
    },
    {
      key: 'facebook',
      label: translations.facebook
    },
    {
      key: 'phone_number',
      label: translations.phone
    },
    {
      key: 'user_email',
      label: translations.user
    },
    {
      key: 'updated_at',
      label: translations.updated_at
    },
    {
      key: 'premium',
      label: 'Premium',
      formatter: (value: boolean) => value ? '⭐' : '❌'
    },
  ],

  order: {
    id: 'desc'
  },

  actions: [
    {
      label: '',
      icon: 'edit',
      position: 'row',
      route: {
        url: 'https://headcounter.app',
        param: 'slug'
      }
    },
    {
      label: 'Delete space',
      icon: 'delete',
      position: 'bulk',
      route: {
        name: '/spaces/deletes',
        param: 'id'
      }
    },
    // {
    //   label: translations.create,
    //   position: 'top-right',
    //   icon: 'library_add',
    //   route: {
    //     name: '/spaces/add'
    //   }
    // }
  ],
  visibleFields: {
    id: true,
    name: true,
    current: true,
    user_email: true,
    updated_at: true,
    premium: true,
  },
  searchFields: {
    name: true,
  },
  controls: true,
  refreshAction: 'fetchSpaces',
  selectionGetter: 'getSelectedSpaces'
});

export interface ISpaceForm {
  name?: IInput;
  current?: IInput;
  capacity?: IInput;
  slug?: IInput;
  website?: IInput;
  phone_number?: IInput;
}

export const form: (translations: ISpacesTranslations) => ISpaceForm = translations => ({
});

export const spacesModal: IModal = {
  isActive: true,
  close: () => {
    router.push('/spaces');
  }
};

export const spacesOffcanvas: IOffcanvas = {
  isActive: true,
  close: () => {
    router.push('/spaces');
  }
};

export const spaceModel: Partial<IStateSpace> = {
  name: '',
};

export const dropdown: IDropdown = {
  isActive: false,
  position: 'right'
};

export const spaceActionPopup: IPopup = {
  message: '',
  type: '',
  isActive: false
};
