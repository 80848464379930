import { addDeviceCategory, deleteDeviceCategory, editDeviceCategory, getDeviceCategories } from '@/api/deviceCategories';
import { IDeviceCategory } from '@/models/deviceCategory';
import { ActionContext } from 'vuex';

import { IState } from '..';
import { IObjectState, IStateObject } from '../';

import config from './config';
/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateDeviceCategory extends IObjectState, IDeviceCategory {

}
export interface IDeviceCategoriesState extends IStateObject<IStateDeviceCategory> {
}

const state: IDeviceCategoriesState = {
  selected: [],
  list: [],
  loading: false,
  message: '',
  error: false
};

const addStateProps = (deviceCategories: IDeviceCategory[]) =>
  deviceCategories.map(deviceCategory => ({
    ...deviceCategory,
    loading: false,
    error: false,
    message: ''
  }));

const getters = {
  getDeviceCategories: (state: IDeviceCategoriesState) => state.list,
  getSelectedDeviceCategories: (state: IDeviceCategoriesState) => state.selected,
  getDeviceCategoriesLoading: (state: IDeviceCategoriesState) => state.loading,
  getDeviceCategoriesError: (state: IDeviceCategoriesState) => state.error,
  getDeviceCategoriesMessage: (state: IDeviceCategoriesState) => state.message,
};

const actions = {

  fetchDeviceCategories(
    context: ActionContext<IDeviceCategoriesState, IState>
  ) {

    context.commit('setDeviceCategoriesLoading');

    getDeviceCategories()
      .then(response => {
        context.commit('setDeviceCategories', addStateProps(response.data));
      })
      .catch(err => {
        context.commit('setDeviceCategoriesError', err.message);
      });

  },

  addDeviceCategory(context: ActionContext<IDeviceCategoriesState, IState>, deviceCategory: IDeviceCategory) {
    context.commit('setDeviceCategoriesLoading');

    addDeviceCategory(deviceCategory)
      .then(response => {
        context.commit('newDeviceCategory', addStateProps([response.data])[0]);
      })
      .catch(err => {
        context.commit('setDeviceCategoriesError', err.message);
      });

  },

  editDeviceCategory(context: ActionContext<IDeviceCategoriesState, IState>, editedDeviceCategory: IStateDeviceCategory) {
    const deviceCategory = context.state.list.find(deviceCategory => editedDeviceCategory.id === deviceCategory.id);
    context.commit('setDeviceCategoryLoading', deviceCategory);

    editDeviceCategory(editedDeviceCategory)
      .then(response => {
        context.commit('editDeviceCategory', response.data);
      })
      .catch(err => {
        context.commit('setDeviceCategoryError', { failedDeviceCategory: editedDeviceCategory, message: err.message });
      });

  },
  deleteDeviceCategory(context: ActionContext<IDeviceCategoriesState, IState>, deletedDeviceCategory: IStateDeviceCategory) {
    const deviceCategory = context.state.list.find(deviceCategory => deletedDeviceCategory.id === deviceCategory.id);
    context.commit('setDeviceCategoryLoading', deviceCategory);

    deleteDeviceCategory(deletedDeviceCategory)
      .then(response => {
        context.commit('removeDeviceCategory', deletedDeviceCategory);
      })
      .catch(err => {
        context.commit('setDeviceCategoryError', { failedDeviceCategory: deletedDeviceCategory, message: err.message });
      });

  }
};

const mutations = {

  setDeviceCategoriesError: (state: IDeviceCategoriesState, message: string) => (
    (state.loading = false),
    (state.error = true),
    (state.message = message)
  ),

  setDeviceCategoryError: (state: IDeviceCategoriesState, { failedDeviceCategory, message }: { failedDeviceCategory: IStateDeviceCategory, message: string }) => (
    (state.list = state.list.map(deviceCategory => deviceCategory.id === failedDeviceCategory.id ? {
      ...deviceCategory,
      loading: false,
      error: true,
      message
    } : deviceCategory))
  ),

  setDeviceCategoriesLoading: (state: IDeviceCategoriesState) => (
    (state.loading = true),
    (state.error = false)
  ),

  setDeviceCategoryLoading: (state: IDeviceCategoriesState, deviceCategory: IStateDeviceCategory) => (
    (state.list[state.list.indexOf(deviceCategory)] = {
      ...state.list[state.list.indexOf(deviceCategory)],
      loading: true,
      error: false
    })
  ),

  setDeviceCategories: (state: IDeviceCategoriesState, deviceCategories: IStateDeviceCategory[]) => (
    (state.error = false),
    (state.loading = false),
    (state.list = deviceCategories)
  ),

  editDeviceCategory: (state: IDeviceCategoriesState, editedDeviceCategory: IStateDeviceCategory) => (
    (state.list = state.list.map(
      deviceCategory => deviceCategory.id === editedDeviceCategory.id ? {
        ...editedDeviceCategory,
        loading: false,
        error: false
      } : deviceCategory
    ))
  ),

  newDeviceCategory: (state: IDeviceCategoriesState, device: IStateDeviceCategory) => (
    (state.loading = false),
    (state.error = false),
    state.list.unshift(device)
  ),

  removeDeviceCategory: (state: IDeviceCategoriesState, deletedDeviceCategory: IDeviceCategory) =>
    (
      state.list = state.list.filter(deviceCategory => deviceCategory.id !== deletedDeviceCategory.id)
    ),

  removeDeviceCategories: (state: IDeviceCategoriesState, ids: number[]) =>
    (
      state.list = state.list.filter(deviceCategory => deviceCategory.id && ids.indexOf(deviceCategory.id) === -1)
    ),

  selectDeviceCategory: (state: IDeviceCategoriesState, deviceCategory: IStateDeviceCategory) => {
    if (deviceCategory.id) {
      const eventIndex = state.selected.indexOf(deviceCategory.id);
      if (eventIndex > -1) {
        state.selected.splice(eventIndex, 1);
      } else {
        state.selected.push(deviceCategory.id);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
