
import { IUsersTranslations } from '../';

const users: IUsersTranslations = {
  name: 'Nome',
  users: 'Utilizadores',
  email: 'Email',
  phone: 'Número de telemóvel',
  deleteUsers: 'Eliminar Utilizadores',
  delete_users_confirm: 'Tem a certeza que pretende eliminar os utilizadores selecionados? Esta ação não pode ser desfeita.',
};

export default users;
