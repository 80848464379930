
import { IDeviceCategory } from '@/models/deviceCategory';
import axios from 'axios';

import config from './config';

interface IResponse<t> {
  data: t;
  status: number;
}

const url = process.env.VUE_APP_SERVER_PATH;

export const getDeviceCategories = () => new Promise((resolve: (response: IResponse<IDeviceCategory[]>) => void, reject) => {

  axios
    .get(`${url}/admin/device-categories?page_size=500`, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const addDeviceCategory = (deviceCategory: IDeviceCategory) => new Promise((resolve: (response: IResponse<IDeviceCategory>) => void, reject) => {

  axios
    .post(`${url}/admin/device-categories`, deviceCategory, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const editDeviceCategory = (deviceCategory: IDeviceCategory) => new Promise((resolve: (response: IResponse<IDeviceCategory>) => void, reject) => {

  axios
    .put(`${url}/admin/device-categories/${deviceCategory.id}`, deviceCategory, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const deleteDeviceCategory = (deviceCategory: IDeviceCategory) => new Promise((resolve: (response: IResponse<IDeviceCategory>) => void, reject) => {

  axios
    .delete(`${url}/admin/device-categories/${deviceCategory.id}`, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});
