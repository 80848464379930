import { IModal, IOffcanvas, IPopup } from '@/components';
import { IInput, ISelect, ITextarea } from '@/components/form';
import { ITable } from '@/components/table';
import router from '@/router';
import { IStateDevice } from '@/store/modules/devices';
import { IDevicesTranslations } from '@/translations';

export const table: (translations: IDevicesTranslations) => ITable<IStateDevice> = translations => ({
  id: 'devices-table',
  columns: [
    {
      key: 'name',
      label: translations.name
    },
    {
      key: 'model_name',
      label: translations.model
    },
    {
      key: 'category_name',
      label: translations.category
    },
    {
      key: 'serial_id',
      label: translations.serial_id
    },
    {
      key: 'mac_address',
      label: translations.mac_address
    },
    {
      key: 'status',
      label: translations.status
    },
    {
      key: 'firmware_version',
      label: translations.firmware_version
    },
    {
      key: 'data_version',
      label: translations.data_version
    },
    {
      key: 'comment',
      label: translations.comment
    },
    {
      key: 'created_at',
      label: translations.created_at
    },
    {
      key: 'updated_at',
      label: translations.updated_at
    },

    {
      key: 'current_event_name',
      label: translations.current_event_name
    },

    {
      key: 'current_exhibitor_name',
      label: translations.current_exhibitor_name
    },
    {
      key: 'key_tag',
      label: translations.key_tag
    },
    {
      key: 'base',
      label: translations.base
    }
  ],
  order: {
    created_at: 'desc'
  },
  actions: [{
    label: translations.edit,
    icon: 'edit',
    position: 'row',
    route: {
      name: '/devices/edit',
      param: 'id'
    }
  }, {
    label: translations.assign,
    icon: 'all_out',
    position: 'bulk',
    route: {
      name: '/devices/assign'
    }
  }, {
    label: translations.receive,
    icon: 'input',
    position: 'bulk',
    route: {
      name: '/devices/receive'
    }
  }, {
    label: translations.change_base,
    icon: 'location_city',
    position: 'bulk',
    route: {
      name: '/devices/change-base'
    }
  }, {
    label: translations.change_status,
    icon: 'mobile_friendly',
    position: 'bulk',
    route: {
      name: '/devices/change-status'
    }
  }, {
    label: translations.change_devices_model,
    icon: 'perm_device_information',
    position: 'bulk',
    route: {
      name: '/devices/change-devices-model'
    }
  }, {
    label: translations.delete,
    icon: 'delete',
    position: 'bulk',
    route: {
      name: '/devices/deletes'
    }
  }, {
    label: translations.categories,
    icon: 'category',
    position: 'top-right',
    route: {
      name: '/devices/categories'
    }
  }, {
    label: translations.models,
    icon: 'perm_device_information',
    position: 'top-right',
    route: {
      name: '/devices/models'
    }
  }, {
    label: translations.export,
    position: 'top-right',
    icon: 'file_copy',
    route: {
      name: '/devices/export'
    }
  }, {
    label: translations.import,
    position: 'top-right',
    icon: 'cloud_upload',
    route: {
      name: '/devices/import'
    }
  }, {
    label: translations.create,
    position: 'top-right',
    icon: 'library_add',
    route: {
      name: '/devices/add'
    }
  }],
  visibleFields: {
    name: true,
    model_name: true,
    current_event_name: true,
    status: true,
    category_name: true,
    base: true,
    created_at: true
  },
  searchFields: {
    name: true,
    model_name: true,
    serial_id: true,
    status: true,
    category_name: true,
    base: true
  },
  controls: true,
  refreshAction: 'fetchDevices',
  selectionGetter: 'getSelectedDevices'
});

export const deviceForm: IDeviceForm = {
  name: {
    id: 'name',
    label: 'Name',
    type: 'number',
    placeholder: 'Enter device name',
    value: ''
  },
  serial_id: {
    id: 'serial-id',
    label: 'Serial ID',
    placeholder: 'Enter serial id',
    type: 'text',
    value: ''
  },
  mac_address: {
    id: 'mac-address',
    label: 'Mac Address',
    placeholder: 'Enter mac address',
    type: 'text',
    value: ''
  },
  status: {
    id: 'status',
    label: 'Status',
    options: [
      {
        label: 'In Use',
        value: 'In Use'
      },
      {
        label: 'Available',
        value: 'Available'
      },
      {
        label: 'Repairing',
        value: 'Repairing'
      },
      {
        label: 'Receiving',
        value: 'Receiving'
      },
      {
        label: 'Missing',
        value: 'Missing'
      }
    ],
    value: 'Available'
  },
  firmware_version: {
    id: 'firmware-version',
    label: 'Firmware version',
    placeholder: 'Enter firmware version',
    type: 'text',
    value: ''
  },
  data_version: {
    id: 'data-version',
    label: 'Data version',
    placeholder: 'Enter data version',
    type: 'text',
    value: ''
  },
  comment: {
    id: 'comment',
    label: 'Comment',
    placeholder: 'Enter a note or a comment',
    value: ''
  },

  base: {
    id: 'base',
    label: 'Base',
    type: 'text',
    value: ''
  }
};

export const deviceActionPopup: IPopup = {
  message: '',
  type: '',
  isActive: false
};

export interface IDeviceForm {
  name: IInput;
  serial_id: IInput;
  mac_address: IInput;
  status: ISelect;
  firmware_version: IInput;
  data_version: IInput;
  comment: ITextarea;
  base: IInput;
}

export const deviceModal: IModal = {
  isActive: true,
  close: () => {
    router.push('/devices');
  }
};

export const deviceOffcanvas: IOffcanvas = {
  isActive: true,
  close: () => {
    router.push('/devices');
  }
};

export const deviceModel: IStateDevice = {
  name: '',
  serial_id: '',
  mac_address: '',
  status: '',
  firmware_version: '',
  data_version: '',
  comment: '',
  loading: false,
  error: false,
  message: ''
};
