

















// @ is an alias to /src
import { ISelect } from '@/components/form';
import Select from '@/components/form/select.vue';
import Modal from '@/components/modal.vue';
import router from '@/router';
import store from '@/store';
import {
  IBulkDevicesEvent,
  IBulkDevicesStatus,
  IStateDevice
} from '@/store/modules/devices';
import { IStateEvent } from '@/store/modules/events';
import { IDevicesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

import { deviceForm, deviceModal } from '../';

@Component({
  name: 'ChangeDevicesStatus',
  components: { Modal, Select }
})
class ChangeDevicesStatus extends Vue {
  @Action('changeDevicesStatus') public changeStatus!: (devices: IBulkDevicesStatus) => void;

  @Action('assignDevices') public assign!: (devices: IBulkDevicesEvent) => void;

  @Getter('getSelectedDevices') public selectedDevices!: number[];

  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public status = '';
  public modal = deviceModal;
  public selectStatus = deviceForm.status;
  public created() {}
  public submit() {
    if (this.status !== '') {
      this.changeStatus({ ids: this.selectedDevices, status: this.status });
      router.push('/devices');
    }
  }
  public close() {
    router.push('/devices');
  }
}

export default ChangeDevicesStatus;
