import axios from 'axios';
import { ActionContext } from 'vuex';

import { IState } from '..';
import { IObjectState } from '../';
/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/

export interface IStateDeviceModel extends IObjectState {

}
export interface IDevicesWizardState extends IObjectState {
  out: IDevicesWizardOutForm;
  success: boolean;
}
export interface IDevicesWizardOutForm {
  location: string;
  event: number;
  devices: IDevicesWizardDevice[];
}
export interface IDevicesWizardDevice {
  category: string;
  amount: number;
}
const state: IDevicesWizardState = {
  out: {
    location: '',
    event: 0,
    devices: []
  },
  loading: false,
  message: '',
  error: false,
  success: false
};

const getters = {
  getDevicesOutForm: (state: IDevicesWizardState) => state.out,
  getDevicesOutLoading: (state: IDevicesWizardState) => state.loading,
  getDevicesOutError: (state: IDevicesWizardState) => state.error,
  getDevicesOutMessage: (state: IDevicesWizardState) => state.message,
};

const actions = {
  submitOutDevices: (context: ActionContext<IDevicesWizardState, IState>) => {
    context.commit('setUsersLoading');

    axios
      .post('https://b.beamian.com/api/v2/users/', {})
      .then(response => {
        context.commit('newUser', response.data);
      })
      .catch(err => { });
  }
};

const mutations = {

  resetDevicesWizard: (state: IDevicesWizardState) => (
    state.loading = false,
    state.error = false,
    state.success = false,
    state.message = ''
  ),

  setDevicesOutDevices: (state: IDevicesWizardState, devices: IDevicesWizardDevice[]) => (
    (state.out.devices = devices)
  ),

  setDevicesOutLocation: (state: IDevicesWizardState, location: string) => (
    (state.out.location = location)
  ),

  setDevicesOutEvent: (state: IDevicesWizardState, id: number) => (
    (state.out.event = id)
  ),

  setDevicesWizardSuccess: (state: IDevicesWizardState, message: string) => (
    (state.loading = false),
    (state.error = false),
    (state.success = true)
  ),

  setDevicesWizardError: (state: IDevicesWizardState, message: string) => (
    (state.loading = false),
    (state.error = true),
    (state.success = true),
    (state.message = message)
  ),

  setDevicesWizardLoading: (state: IDevicesWizardState) => (
    (state.loading = true),
    (state.success = false),
    (state.error = false)
  ),

};

export default {
  state,
  getters,
  actions,
  mutations
};
