import { IModal, IOffcanvas } from '@/components';
import { IInput, ITextarea } from '@/components/form';
import { ITable } from '@/components/table';
import router from '@/router';
import { IStateDeviceCategory } from '@/store/modules/deviceCategories';
import { IDeviceCategoriesTranslations } from '@/translations';

export const table: (translations: IDeviceCategoriesTranslations) => ITable<IStateDeviceCategory> = translations => ({
  id: 'device-categories-table',
  columns: [
    {
      key: 'name',
      label: translations.name
    },
    {
      key: 'description',
      label: translations.model
    }
  ],
  order: {
    name: 'desc'
  },
  actions: [{
    label: translations.edit,
    icon: 'edit',
    position: 'row',
    route: {
      name: '/devices/categories/edit',
      param: 'id'
    }
  },
    {
      label: translations.create,
      position: 'top-right',
      icon: 'library_add',
      route: {
        name: '/devices/categories/add'
      }
    },
    {
      label: translations.delete,
      position: 'bulk',
      icon: 'delete',
      route: {
        name: '/devices/categories/deletes'
      }
    }],
  visibleFields: {
    name: true,
    description: true
  },
  searchFields: {
    name: true,
    description: true
  },
  controls: true,
  insideModal: true,
  refreshAction: 'fetchDeviceCategories',
  selectionGetter: 'getSelectedDeviceCategories'

});

export interface IDeviceCategoryForm {
  name: IInput;
  description: ITextarea;
}
export const form: (translations: IDeviceCategoriesTranslations) => IDeviceCategoryForm = translations => ({
  name: {
    id: 'device-category-name',
    label: translations.device_category_name,
    type: 'text',
    placeholder: translations.device_category_name_placeholder,
    value: ''
  },

  description: {
    id: 'device-category-description',
    label: translations.device_category_description,
    placeholder: translations.device_category_description_placeholder,
    value: ''
  },
});

export const deviceCategoryModal: IModal = {
  isActive: true,
  close: () => {
    router.push('/devices/categories');
  }
};

export const deviceCategoryOffcanvas: IOffcanvas = {
  isActive: true,
  close: () => {
    router.push('/devices/categories');
  }
};

export const deviceCategory: IStateDeviceCategory = {
  name: '',
  description: '',
  loading: false,
  error: false,
  message: ''
};
