























// @ is an alias to /src
import { IVSelect } from '@/components/form';
import VueSelect from '@/components/form/vue-select/vue-select.vue';
import Modal from '@/components/modal.vue';
import router from '@/router';
import store from '@/store';
import { IBulkDevicesEvent, IStateDevice } from '@/store/modules/devices';
import { IStateEvent } from '@/store/modules/events';
import { IDevicesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import { deviceModal } from './../';

@Component({
  name: 'AssignDevices',
  components: { Modal, VueSelect }
})
class AssignDevices extends Vue {
  @Action('fetchEvents') public fetch!: () => void;

  @Getter('getEvents') public events!: IStateEvent[];

  @Action('assignDevices') public assign!: (devices: IBulkDevicesEvent) => void;

  @Getter('getSelectedDevices') public selected!: number[];

  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public eventId = '';
  public modal = deviceModal;
  public selectEvent: IVSelect = {
    id: 'select-event',
    label: 'Select event',
    placeholder: 'Select Event',
    value: '',
    options: []
  };
  public id = 0;
  public created() {
    if (this.events.length > 0) {
      this.mapEventsToSelectOptions();
    } else {
      this.fetch();
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setEvents') {
         this.mapEventsToSelectOptions();
         unsubscribe();
       }
      });
    }
  }
  public mapEventsToSelectOptions() {
    this.selectEvent = {
      ...this.selectEvent,
      options: this.events.map(event => ({
        value: event.id ? event.id : 0,
        label: event.name
      }))
    };
  }
  public submit() {
    if (this.eventId !== '') {
      const eventId = parseInt(this.eventId, 10);
      const event = this.events.find(event => event.id === eventId);
      if (event) {
        this.assign({
         ids: this.selected,
         current_event_id: eventId,
         event_name: event.name
       });
      }
    }
    router.push('/devices');
  }
  public close() {
    router.push('/devices');
  }
}

export default AssignDevices;
