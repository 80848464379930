import { IBooklet, IBookletCover, IBrandField, IEvent } from '@/models/event';
import { IEventManager } from '@/models/event-manager';
import axios from 'axios';

import { Resolve } from './';
import config from './config';

interface IData<T> {
  results: T[];
  item_count: number;
  page_count: number;
  current: number;
  next: number;
  previous: number;
}

interface IEventSettings {
  id: number;
  live_interactions: boolean;
}

const url = process.env.VUE_APP_SERVER_PATH;

export const getEvents = () => new Promise((resolve: Resolve<IEvent[]>, reject) => {
  axios
    .get(`${url}/v1/events`, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const getEventSettings = (event: number) => new Promise(
  (resolve: Resolve<IData<IEventSettings>>, reject) => {
    axios
      .get(`${url}/v2/event-settings?event=${event}`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const editEventSettings = (eventSettings: IEventSettings) => new Promise((resolve: Resolve<any>, reject) => {
  axios
    .patch(`${url}/v2/event-settings/${eventSettings.id}`, eventSettings, config())
    .then(response => {
      debugger;
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const addEvent = (event: IEvent) => new Promise((resolve: Resolve<IEvent>, reject) => {
  axios
    .post(`${url}/events`, event, config())
    .then(response => {

      resolve(response);

    })
    .catch(err => {
      reject(err);
    });
});

export const getEventManagers = (eventId: number) =>
  new Promise((resolve: Resolve<IData<IEventManager>>, reject) => {
    axios
      .get(`${url}/v2/event-managers?event=${eventId}`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const getBooklet = (eventId: number) =>
  new Promise((resolve: Resolve<IData<IBooklet>>, reject) => {
    axios
      .get(`${url}/v2/booklets?event=${eventId}`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const addBooklet = (booklet: IBooklet) =>
  new Promise((resolve: Resolve<IBooklet>, reject) => {
    axios
      .post(`${url}/v2/booklets`, booklet, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const editBooklet = (bookletId: number, booklet: IBooklet) =>
  new Promise((resolve: Resolve<IData<IBooklet>>, reject) => {
    axios
      .put(`${url}/v2/booklets/${bookletId}`, booklet, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const getParticipatingFields = (eventId: number) =>
  new Promise((resolve: Resolve<IData<IBrandField>>, reject) => {
    axios
      .get(`${url}/v2/participating-fields?event=${eventId}&page_size=100`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const addEventManager = (eventManager: { user_account: number, event: number; }) =>
  new Promise((resolve: Resolve<any>, reject) => {
    axios
      .post(`${url}/v2/event-managers`, eventManager, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const deleteEventManager = (id: number) =>
  new Promise((resolve: Resolve<any>, reject) => {
    axios
      .delete(`${url}/v2/event-managers/${id}`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const editEvent = (event: IEvent) =>
  new Promise((resolve: Resolve<IEvent[]>, reject) => {
    axios
      .put(`${url}/events/${event.id}`, event, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const deleteEvent = (event: IEvent) =>
  new Promise((resolve: Resolve<IEvent[]>, reject) => {
    // axios
    // .delete(`${url}/v2/events/${deletedEvent.id}`, config())
    // .then(response => {
    //   context.commit('removeEvent', deletedEvent);
    // })
    // .catch(err => {
    //   context.commit('setEventError', { failedEvent: deletedEvent, message: err.message });
    // });
  });

export const uploadEventImage = (formData: FormData) =>
  new Promise((resolve: Resolve<{ id: number }>) => {
    axios
      .post(
        `${url}/v2/event-images`,
        formData,
        config(true)
      )
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        resolve(err);
      });

  });
export const addBookletImage = (bookletId: number, formData: FormData) =>
  new Promise((resolve: Resolve<{ id: number }>) => {
    axios
      .put(
        `${url}/v2/booklet-covers/${bookletId}`,
        formData,
        config(true)
      )
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        resolve(err);
      });

  });

export const updateBookletImage = (bookletId: number, coverImage: IBookletCover) =>
  new Promise((resolve: Resolve<{ id: number }>) => {
    axios
      .put(
        `${url}/v2/booklet-covers/${bookletId}`,
        coverImage,
        config(true)
      )
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        resolve(err);
      });

  });
