
import { ISpacesTranslations } from '../';

const spaces: ISpacesTranslations = {
  name: 'Name',
  spaces: 'Spaces',
  deleteSpaces: 'Delete spaces',
  currentCount: 'Current count',
  capacity: 'Capacity',
  slug: 'Slug',
  website: 'Website',
  facebook: 'Facebook URL',
  phone: 'Phone number',
  delete_spaces_confirm: 'Are you sure you want to delete these spaces?',
  user: 'User',
  updated_at: 'Last update',
};

export default spaces;
