






















// @ is an alias to /src
import { IOffcanvas } from '@/components';
import { IInput } from '@/components/form';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Textarea from '@/components/form/textarea.vue';
import Offcanvas from '@/components/offcanvas.vue';
import { IDevice } from '@/models/device';
import router from '@/router';
import store from '@/store';
import { IStateDevice } from '@/store/modules/devices';
import { IDevicesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import { deviceForm, deviceModel, deviceOffcanvas } from './../';

@Component({
  name: 'AddDevice',
  components: { Offcanvas, Input, Select, Textarea }
})
class AddDevice extends Vue {
  @Action('addDevice') public add!: (device: IStateDevice) => void;

  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public offcanvas = deviceOffcanvas;
  public form = deviceForm; public device = deviceModel;
  public created() {}
  public submit() {
    this.add(this.device);
    const unsubscribe = store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setDevicesError') {
        const errors = mutation.payload;
        const errorKeys = Object.keys(errors) as Array<
          Extract<IDevice, string>
        >;
        const form = {} as any;

        errorKeys.forEach(key => {
         form[key] = {
            ...(this.form[key] as object),
            error: true,
            message: errors[key][0]
          };
       });

        this.form = { ...this.form, ...form };
      }

      if (mutation.type === 'newDevice') {
        router.push('/devices');
        unsubscribe();
      }
    });
  }
  public discard() {
    router.push('/devices');
  }
}

export default AddDevice;
