import { ITableTranslations } from '..';

const table: ITableTranslations = {
  showing: 'Showing',
  of: 'of',
  items: 'items',
  see: 'See',
  see_selected_only: 'See selected only',
  clear_selection: 'Clear selection',
  all_items: 'All items',
  items_per_page: 'Items per page:',
  previous: 'Previous',
  next: 'Next',
  table_settings: 'Table settings',
  search_settings: 'Search settings',
  refresh_table: 'Refresh table',
  show_hide_columns: 'Show/hide columns',
  search_fields: 'Search fields',
  search_data: 'Search'
};

export default table;
