














// @ is an alias to /src
import { importDevices } from '@/api/devices';
import { IInput } from '@/components/form';
import Input from '@/components/form/input.vue';
import Modal from '@/components/modal.vue';
import router from '@/router';
import store from '@/store';
import { IDevicesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { deviceModal } from '../';

@Component({
  name: 'ImportDevices',
  components: { Modal, Input }
})
class ImportDevices extends Vue {
  @Action('fetchDevices') public fetch!: () => void;

  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public modal = deviceModal; public loading = false;
  public importFile: IInput = {
    id: 'import-devices',
    label: 'Upload .xlsx file',
    type: 'file',
    value: ''
  };
  public file!: File;
  public created() {}
  public submit() {
    this.loading = true;
    if (this.file) {
      const fileForm = new FormData();
      fileForm.append('file', this.file);
      importDevices(fileForm).then(response => {
        this.loading = false;
        this.fetch();
        router.push('/devices');
      });
    }
  }
  public close() {
    router.push('/devices');
  }
}

export default ImportDevices;
