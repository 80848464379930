import store from '@/store';

export default (formData = false, arrayBuffer = false) => {
  const { token } = store.getters.userAuthState;
  const contentType = formData ? 'multipart/form-data;' : 'application/vnd.beamian+json; version=1';
  return {
    headers: {
      // tslint:disable-next-line: object-literal-key-quotes
      'Authorization': `Token ${token}`,
      'content-type': contentType,
      'vnd.beamian.role': 'admin',
    }
  };

};
