import { deleteUser, editUser, fetchUsers } from '@/api/users';
import { IUser } from '@/models/user';
import { Dictionary } from 'vue-router/types/router';
import { ActionContext } from 'vuex';

import { IState } from '..';
import { IObjectState, IStateObject } from '../';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateUser extends IUser, IObjectState {

}
export interface IUsersState extends IStateObject<IStateUser> {

}

const state: IUsersState = {
  list: [],
  selected: [],
  loading: false,
  message: '',
  error: false,
  pages: 0
};

const addStateProps = (events: IUser[]) =>
  events.map(event => ({
    ...event,
    loading: false,
    success: false,
    error: false,
    message: ''
  }));

const getters = {
  getUsers: (state: IUsersState) => state.list,
  getUsersState: (state: IUsersState) => ({
    loading: state.loading,
    error: state.error,
    message: state.message,
  }),
  getSelectedUsers: (user: IUsersState) => user.selected,
  getPages: (state: IUsersState) => state.pages
};

const actions = {

  fetchUsers(
    context: ActionContext<IUsersState, IState>,
    params: { page: number, pageSize: number, search?: string }
  ) {
    context.commit('setUsersLoading');
    fetchUsers(params.page, params.pageSize, params.search)
      .then(response => {
        context.commit('setUsers', addStateProps(response.data.results));
        context.commit('setPages', response.data.page_count);
      })
      .catch(err => {
        context.commit('setUsersError', err.message);
      });

  },

  deleteUsers(context: ActionContext<IUsersState, IState>, userIds: number[]) {
    context.commit('setUsersLoading');

    // Create an array of promises for each user deletion
    const deletePromises = userIds.map(userId => {
      return deleteUser(userId)
        .then(response => {
          return userId;
        })
        .catch(err => {
          throw new Error(`Failed to delete user with ID ${userId}: ${err.message}`);
        });
    });

    // Wait for all deletions to complete
    Promise.all(deletePromises)
      .then(deletedUserIds => {
        // Remove all deleted users from the state
        deletedUserIds.forEach(userId => {
          context.commit('removeUser', { id: userId });
        });
      })
      .catch(err => {
        context.commit('setUsersError', err.message);
      })
      .finally(() => {
        // Refresh the users list
        context.dispatch('fetchUsers', { page: 1, pageSize: 20 });
      });
  },

  // addUser(context: ActionContext<IUsersState, IState>, user: IUser) {
  //   context.commit('setUsersLoading');

  //   setTimeout(() => {
  //     context.commit('newUser', addStateProps([user])[0]);
  //   }, 2000);
  //   // axios
  //   //   .post('https://b.beamian.com/api/v2/users/', user)
  //   //   .then(response => {
  //   //     context.commit('newUser', response.data);
  //   //   })
  //   //   .catch(err => { });

  // },

  // editUser(context: ActionContext<IUsersState, IState>, editedUser: IStateUser) {
  //   context.commit('setLoadingUser', context.state.list.find(user => editedUser.id === user.id));

  //   editUser(editedUser)
  //     .then(response => {
  //       context.commit('editUser', editedUser);
  //     })
  //     .catch(err => {
  //       context.commit('newUser', err.message);
  //     });

  // },

  // deleteUser(context: ActionContext<IUsersState, IState>, user: IStateUser) {

  //   context.commit('setLoadingUser', user);

  //   setTimeout(() => {
  //     context.commit('removeUser', user);
  //   }, 2000);

  //   // axios
  //   //   .delete(`https://b.beamian.com/api/v2/users/${user.slug}/`)
  //   //   .then(response => {
  //   //     context.commit('deleteUser', user);
  //   //   })
  //   //   .catch(err => { });

  // }
};

const mutations = {

  resetUsers: (state: IUsersState, message: string) => (
    state.loading = false,
    state.error = false,
    state.message = ''
  ),

  setUsersError: (state: IUsersState, message: string) => (
    (state.loading = false),
    (state.error = true),
    (state.message = message)
  ),

  setUsersLoading: (state: IUsersState) => (
    (state.loading = true),
    (state.error = false)
  ),

  setLoadingUser: (state: IUsersState, user: IStateUser) => (
    (state.list[state.list.indexOf(user)] = {
      ...state.list[state.list.indexOf(user)],
      loading: true,
      error: false
    }),
    (state.error = false)
  ),

  setUsers: (state: IUsersState, users: IStateUser[]) => (
    (state.loading = false),
    (state.list = users)
  ),

  setPages: (state: IUsersState, pages: number) => (
    (state.pages = pages)
  ),

  editUser: (state: IUsersState, editedUser: IStateUser) => (
    (state.list = state.list.map(
      user => user.id === editedUser.id ? {
        ...editedUser,
        loading: false,
        error: false
      } : user
    ))
  ),

  newUser: (state: IUsersState, user: IStateUser) => (
    (state.loading = false),
    (state.error = false),
    state.list.unshift(user)
  ),

  removeUser: (state: IUsersState, deletedUser: IStateUser) =>
  (
    state.list = state.list.filter(user => user.id !== deletedUser.id)
  ),
  selectUser: (state: IUsersState, user: IStateUser) => {
    if (user.id) {
      const userIndex = state.selected.indexOf(user.id);
      if (userIndex > -1) {
        state.selected.splice(userIndex, 1);
      } else {
        state.selected.push(user.id);
      }
    }
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
