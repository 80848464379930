
















// @ is an alias to /src
import Select from '@/components/form/select.vue';
import Modal from '@/components/modal.vue';
import router from '@/router';
import { ISpacesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { spacesModal } from '../';

@Component({
  name: 'DeleteSpaces',
  components: { Modal, Select },
})
class DeleteSpaces extends Vue {
  @Action('deleteSpaces') public delete!: (ids: number[]) => void;

  @Getter('getSelectedSpaces') public selectedSpaces!: number[];

  @Getter('getSpacesTranslations') public translations!: ISpacesTranslations;
  public base = '';
  public modal = spacesModal;
  public created() {}
  public submit() {
    this.delete(this.selectedSpaces);
    router.push('/spaces');
  }
  public close() {
    router.push('/spaces');
  }
}

export default DeleteSpaces;
