

















// @ is an alias to /src
import { ISelect } from '@/components/form';
import Select from '@/components/form/select.vue';
import Modal from '@/components/modal.vue';
import { IDeviceCategory } from '@/models/deviceCategory';
import router from '@/router';
import store from '@/store';
import { IStateDeviceCategory } from '@/store/modules/deviceCategories';
import { IStateDeviceModel } from '@/store/modules/deviceModels';
import { IStateDevice } from '@/store/modules/devices';
import { IDeviceModelsTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import { deviceModelModal } from './../DeviceModels.data';

@Component({
  name: 'ChangeDevicesCategory',
  components: { Modal, Select }
})
class ChangeDevicesCategory extends Vue {
  @Action('deleteDeviceModel') public delete!: (device: IStateDeviceModel) => void;

  @Getter('getSelectedDeviceModels') public selectedDeviceModels!: number[];

  @Getter('getDeviceModels') public deviceModels!: IStateDeviceModel[];

  @Getter('getDeviceModelsTranslations') public translations!: IDeviceModelsTranslations;
  public categoryId = '';
  public modal = deviceModelModal;
  public selectCategory: ISelect = {
    id: 'select-device-category',
    default: 'Select device model category',
    value: '',
    options: []
  };
  public id = 0;
  public created() {}
  public submit() {
    if (this.selectedDeviceModels.length > 0) {
      this.selectedDeviceModels.forEach(async id => {
        const deviceModel = this.deviceModels.find(
          deviceModel => deviceModel.id === id
        );
        if (deviceModel) {
         this.delete(deviceModel);
       }
      });
    }
    router.push('/devices/models');
  }
  public close() {
    router.push('/devices/models');
  }
}

export default ChangeDevicesCategory;
