




















// @ is an alias to /src
import { exportDevices } from '@/api/devices';
import Modal from '@/components/modal.vue';
import router from '@/router';
import store from '@/store';
import { IDevicesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { deviceModal } from '../';

@Component({
  name: 'ExportDevices',
  components: { Modal }
})
class ExportDevices extends Vue {
  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public modal = deviceModal; public loading = false;
  public created() {}
  public submit() {
    this.loading = true;
    exportDevices().then(response => {
      this.loading = false;
      const fileURL = window.URL.createObjectURL(
        new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      );
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;

      fileLink.setAttribute('download', 'Devices.xlsx');

      document.body.appendChild(fileLink);

      fileLink.click();
      router.push('/devices');
    });
  }
  public close() {
    router.push('/devices');
  }
}

export default ExportDevices;
