import translations, { IDeviceCategoriesTranslations, IDeviceModelsTranslations, IDevicesTranslations, IEventsTranslations } from '@/translations';
import { ISpacesTranslations, IUsersTranslations } from '@/translations/index';

export interface ITranslationsState {
  language: 'pt' | 'en' | 'es';
  events: IEventsTranslations | {};
  devices: IDevicesTranslations | {};
  deviceModels: IDeviceModelsTranslations | {};
  deviceCategories: IDeviceCategoriesTranslations | {};
  table: {};
  dashboard: {};
  auth: {};
  common: {};
  spaces: ISpacesTranslations | {};
  users: IUsersTranslations | {};
}

const state: ITranslationsState = {
  language: 'pt',
  events: {},
  devices: {},
  deviceModels: {},
  deviceCategories: {},
  table: {},
  dashboard: {},
  auth: {},
  common: {},
  spaces: {},
  users: {},
};

const getters = {
  getLanguage: (state: ITranslationsState) => state.language,
  getTableTranslations: (state: ITranslationsState) => state.table,
  getEventsTranslations: (state: ITranslationsState) => state.events,
  getDevicesTranslations: (state: ITranslationsState) => state.devices,
  getDeviceModelsTranslations: (state: ITranslationsState) => state.deviceModels,
  getDeviceCategoriesTranslations: (state: ITranslationsState) => state.deviceCategories,
  getDashboardTranslations: (state: ITranslationsState) => state.dashboard,
  getCommonTranslations: (state: ITranslationsState) => state.common,
  getAuthTranslations: (state: ITranslationsState) => state.auth,
  getSpacesTranslations: (state: ITranslationsState) => state.spaces,
  getUsersTranslations: (state: ITranslationsState) => state.users,
};

const mutations = {

  setLanguage: (state: ITranslationsState, language: 'pt' | 'es' | 'en') => (
    (state.language = language),
    (state.devices = translations[language].devices),
    (state.deviceCategories = translations[language].deviceCategories),
    (state.deviceModels = translations[language].deviceModels),
    (state.events = translations[language].events),
    (state.table = translations[language].table),
    (state.dashboard = translations[language].dashboard),
    (state.auth = translations[language].auth),
    (state.common = translations[language].common),
    (state.spaces = translations[language].spaces),
    (state.users = translations[language].users)
  )

};

export default {
  state,
  getters,
  mutations
};
