import { IDropdown, IModal, IOffcanvas, IPopup } from '@/components';
import { ICheckbox, IDatePicker, IInput, ISelect, ITextarea } from '@/components/form';
import { ITable } from '@/components/table';
import router from '@/router';
import { IStateEvent } from '@/store/modules/events';
import { IEventsTranslations } from '@/translations';

export const table: (translations: IEventsTranslations) => ITable<IStateEvent> = translations => ({

  id: 'events-table',
  columns: [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'name',
      label: translations.name
    },
    {
      key: 'slug',
      label: 'Slug'
    },
    {
      key: 'start_date',
      label: translations.start_date
    },
    {
      key: 'end_date',
      label: translations.end_date
    },
    {
      key: 'capacity',
      label: translations.capacity
    },
    {
      key: 'location',
      label: translations.location
    },
    {
      key: 'is_published',
      label: translations.is_published
    },
    {
      key: 'default_language',
      label: translations.default_language
    },
    {
      key: 'base_cost',
      label: translations.base_cost
    },
    {
      key: 'max_capacity',
      label: translations.max_capacity
    }
  ],
  order: {
    start_date: 'asc'
  },

  actions: [
    {
      label: translations.assign_manager,
      icon: 'person_add',
      position: 'bulk',
      single: true,
      route: {
        name: '/events/add-event-manager'
      }
    },
    {
      label: translations.edit,
      icon: 'edit',
      position: 'row',
      route: {
        url: `${process.env.VUE_APP_REDIRECT_URL}events`,
        param: 'id'
      }
    },
    {
      label: translations.live_interactions_email,
      icon: 'folder_shared',
      position: 'bulk',
      route: {
        name: '/events/live-interactions'
      }
    },
    {
      label: translations.setup_booklet,
      icon: 'book',
      position: 'bulk',
      single: true,
      route: {
        name: '/events/booklet'
      }
    },
    // {
    //   label: 'Delete event',
    //   icon: 'delete',
    //   position: 'row',
    //   route: {
    //     name: '/events/delete',
    //     param: 'slug'
    //   }
    // },
    {
      label: translations.create,
      position: 'top-right',
      icon: 'library_add',
      route: {
        name: '/events/add'
      }
    }],
  visibleFields: {
    id: true,
    name: true,
    slug: true,
    location: true,
    start_date: true,
    end_date: true
  },
  searchFields: {
    id: true,
    name: true,
    location: true,
  },
  controls: true,
  refreshAction: 'fetchEvents',
  selectionGetter: 'getSelectedEvents'
});
export const form: (translations: IEventsTranslations) => IEventForm = translations => ({
  name: {
    id: 'event-name',
    label: translations.name,
    maxLength: 30,
    type: 'text',
    placeholder: translations.name_placeholder,
    value: ''
  },
  timezone_id: {
    id: 'event-timezone',
    label: translations.timezone,
    options: [
      {
        label: `${translations.europe}/${translations.london}`,
        value: 'Europe/London',
      },
      {
        label: `${translations.europe}/${translations.lisbon}`,
        value: 'Europe/Lisbon',
      },
      {
        label: `${translations.europe}/${translations.madrid}`,
        value: 'Europe/Madrid',
      },
      {
        label: `${translations.europe}/${translations.berlin}`,
        value: 'Europe/Berlin',
      }],
    value: 'Europe/Lisbon'
  },
  start_date: {
    id: 'event-start_date',
    label: translations.start_date,
    placeholder: '2020-01-30T12:00',
    type: 'datetime-local',
    value: ''
  },
  end_date: {
    id: 'event-end_date',
    label: translations.end_date,
    placeholder: '2020-01-30T12:00',
    type: 'datetime-local',
    value: ''
  },
  location: {
    id: 'event-location',
    label: translations.location,
    type: 'text',
    placeholder: translations.location_placeholder,
    value: ''
  },
  default_language: {
    id: 'event-default_language',
    label: translations.default_language,
    options: [
      {
        label: translations.portuguese,
        value: 'pt',
      },
      {
        label: translations.spanish,
        value: 'es',
      },
      {
        label: translations.english,
        value: 'en',
      },
      {
        label: translations.german,
        value: 'de',
      },
      {
        label: translations.french,
        value: 'fr',
      },
    ],
    value: 'pt'
  },
  description: {
    id: 'event-description',
    label: translations.description,
    placeholder: translations.description_placeholder,
    value: ''
  },
  max_capacity: {
    id: 'event-max_capacity',
    label: translations.max_capacity,
    placeholder: '0',
    type: 'number',
    value: 0
  },
  image: {
    id: 'event-image',
    label: translations.image,
    placeholder: translations.image_placeholder,
    type: 'file',
    value: ''
  },
  background_image: {
    id: 'event-background_image',
    label: translations.background_image,
    placeholder: translations.background_image_placeholder,
    type: 'file',
    value: ''
  },
  is_published: {
    id: 'event-is_published',
    label: translations.is_published,
    value: true
  },
  base_cost: {
    id: 'event-base_cost',
    label: translations.base_cost,
    type: 'number',
    value: 0
  },

});
export const bookletForm: (translations: IEventsTranslations) => IEventBookletForm = translations => ({
  title: {
    id: 'booklet-title',
    name: 'booklet_title',
    label: translations.title,
    type: 'text',
    placeholder: translations.title_placeholder,
    value: ''
  },
  cover: {
    id: 'booklet-cover',
    name: 'cover_image',
    label: translations.cover,
    placeholder: translations.cover_placeholder,
    type: 'file',
    value: ''
  },

  is_published: {
    id: 'booklet-is_published',
    label: translations.is_published,
    value: false
  },

});

export const eventActionPopup: IPopup = {
  message: '',
  type: '',
  isActive: false
};

export interface IEventForm {
  name: IInput;
  start_date: IDatePicker;
  end_date: IDatePicker;
  timezone_id: ISelect;
  max_capacity: IInput;
  description: ITextarea;
  location: IInput;
  default_language: ISelect;
  is_published: ICheckbox;
  image: IInput;
  background_image: IInput;
  base_cost: IInput;
}

export interface IEventBookletForm {
  title: IInput;
  cover: IInput;
  is_published: ICheckbox;
}

export const eventsModal: IModal = {
  isActive: true,
  close: () => {
    router.push('/events');
  }
};

export const eventsOffcanvas: IOffcanvas = {
  isActive: true,
  close: () => {
    router.push('/events');
  }
};

export const eventModel: Partial<IStateEvent> = {
  name: '',
  start_date: '',
  end_date: '',
  timezone_id: '',
  max_capacity: 0,
  description: '',
  location: '',
  default_language: '',
  is_published: false,
  image: 0,
  background_image: 0,
  base_cost: 0,
};

export const deviceActionPopup: IPopup = {
  message: '',
  type: '',
  isActive: false
};

export const bookletIcons = [
  {
    name: 'address',
    label: 'Address',
    path: '/booklet-icons/address_icon.png'
  },
  {
    name: 'company',
    label: 'Company',
    path: '/booklet-icons/company_icon.png'
  },
  {
    name: 'contact',
    label: 'Contact',
    path: '/booklet-icons/contact_icon.png'
  },
  {
    name: 'document',
    label: 'Document',
    path: '/booklet-icons/document_icon.png'
  },
  {
    name: 'email',
    label: 'Email',
    path: '/booklet-icons/email_icon.png'
  },
  {
    name: 'file',
    label: 'File',
    path: '/booklet-icons/file_icon.png'
  },
  {
    name: 'info',
    label: 'Info',
    path: '/booklet-icons/info_icon.png'
  },
  {
    name: 'person',
    label: 'Person',
    path: '/booklet-icons/person_icon.png'
  },
  {
    name: 'phone',
    label: 'Phone',
    path: '/booklet-icons/phone_icon.png'
  },
  {
    name: 'square',
    label: 'square',
    path: '/booklet-icons/square_black.jpg'
  },
  {
    name: 'website',
    label: 'website',
    path: '/booklet-icons/website_icon.png'
  },
  {
    name: 'wine',
    label: 'wine',
    path: '/booklet-icons/wine_icon.png'
  }
];

export const bookletFieldType: ISelect = {
  id: 'field-type',
  value: 'BookletAnswer',
  options: [
    {
      label: 'Title Small',
      value: 'BookletTitleSmall'
    },
    {
      label: 'Title',
      value: 'BookletTitle'
    },
    {
      label: 'Title Big',
      value: 'BookletTitleBig'
    },
    {
      label: 'Answer Small',
      value: 'BookletAnswerSmall'
    },
    {
      label: 'Answer',
      value: 'BookletAnswer'
    },
    {
      label: 'Answer Big',
      value: 'BookletAnswerBig'
    },
    {
      label: 'Link',
      value: 'BookletLink'
    },
    {
      label: 'Email',
      value: 'BookletEmail'
    },
    {
      label: 'Logo',
      value: 'BookletLogo'
    },
    {
      label: 'Brochure',
      value: 'BookletBrochure'
    }
  ]
};

export const dropdown: IDropdown = {
  isActive: false,
  position: 'right'
};
