import ChangeDevicesStatus from '@/views/Devices/Actions/ChangeDevicesStatus.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';

import Dashboard from '../views/Dashboard/Dashboard.vue';
import AddDeviceCategory from '../views/DeviceCategories/Actions/AddDeviceCategory.vue';
import DeleteDeviceCategories from '../views/DeviceCategories/Actions/DeleteDeviceCategories.vue';
import DeleteDeviceCategory from '../views/DeviceCategories/Actions/DeleteDeviceCategory.vue';
import EditDeviceCategory from '../views/DeviceCategories/Actions/EditDeviceCategory.vue';
import AddDeviceModel from '../views/DeviceModels/Actions/AddDeviceModel.vue';
import ChangeDeviceModelsCategory from '../views/DeviceModels/Actions/ChangeDeviceModelsCategory.vue';
import DeleteDeviceModel from '../views/DeviceModels/Actions/DeleteDeviceModel.vue';
import DeleteDeviceModels from '../views/DeviceModels/Actions/DeleteDeviceModels.vue';
import EditDeviceModel from '../views/DeviceModels/Actions/EditDeviceModel.vue';
import AddDevice from '../views/Devices/Actions/AddDevice.vue';
import AssignDevices from '../views/Devices/Actions/AssignDevices.vue';
import ChangeDevicesBase from '../views/Devices/Actions/ChangeDevicesBase.vue';
import ChangeDevicesModel from '../views/Devices/Actions/ChangeDevicesModel.vue';
import DeleteDevice from '../views/Devices/Actions/DeleteDevice.vue';
import DeleteDevices from '../views/Devices/Actions/DeleteDevices.vue';
import EditDevice from '../views/Devices/Actions/EditDevice.vue';
import ExportDevices from '../views/Devices/Actions/ExportDevices.vue';
import ImportDevices from '../views/Devices/Actions/ImportDevices.vue';
import ReceiveDevices from '../views/Devices/Actions/ReceiveDevices.vue';
// import DevicesDirection from '../views/DevicesWizard/partials/direction.vue';
// import InDevicesCompleted from '../views/DevicesWizard/partials/in.completed.vue';
// import InDevices from '../views/DevicesWizard/partials/in.devices.vue';
// import InDevicesEvents from '../views/DevicesWizard/partials/in.events.vue';
// import InDevicesLocation from '../views/DevicesWizard/partials/in.location.vue';
// import InDevicesReview from '../views/DevicesWizard/partials/in.review.vue';
// import OutDevicesCompleted from '../views/DevicesWizard/partials/out.completed.vue';
// import OutDevicesAssignAuto from '../views/DevicesWizard/partials/out.devices.assign.auto.vue';
// import OutDevicesAssignManual from '../views/DevicesWizard/partials/out.devices.assign.manual.vue';
// import OutDevicesAssign from '../views/DevicesWizard/partials/out.devices.assign.vue';
// import OutDevicesScan from '../views/DevicesWizard/partials/out.devices.scan.vue';
// import OutDevicesEvents from '../views/DevicesWizard/partials/out.events.vue';
// import OutDevicesLocation from '../views/DevicesWizard/partials/out.location.vue';
// import OutDevicesReview from '../views/DevicesWizard/partials/out.review.vue';
import AddEvent from '../views/Events/Actions/AddEvent.vue';
import AddEventManager from '../views/Events/Actions/AddEventManager.vue';
import Booklet from '../views/Events/Actions/Booklet.vue';
import LiveInteractions from '../views/Events/Actions/LiveInteractions.vue';
import Home from '../views/Home.vue';
// import EditUser from '../views/Users/Actions/EditUser.vue';
import DeleteSpaces from '../views/Spaces/Actions/DeleteSpaces.vue';
// import AddUser from '../views/Users/Actions/AddUser.vue';
import DeleteUsers from '../views/Users/Actions/DeleteUsers.vue';

// import DeleteEvent from '../views/Events/Actions/DeleteEvent.vue';
// import EditEvent from '../views/Events/Actions/EditEvent.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },

  {
    path: '/events',
    name: 'events',
    // rOute level code-splittIng
    // this generates a separate chunk (about.[hash].js) for this rOute
    // which is lazy-loaded when the rOute is visited.
    component: () => import('../views/Events/Events.vue'),
    children: [
      {
        path: 'add',
        component: AddEvent
      },
      {
        path: 'add-event-manager',
        component: AddEventManager
      },
      {
        path: 'booklet',
        component: Booklet
      },
      {
        path: 'live-interactions',
        component: LiveInteractions
      },
      // {
      //   path: 'edit/:slug',
      //   component: EditEvent
      // },
      // {
      //   path: 'delete/:slug',
      //   component: DeleteEvent
      // },
    ]
  },
  {
    path: '/spaces',
    name: 'spaces',
    // rOute level code-splittIng
    // this generates a separate chunk (about.[hash].js) for this rOute
    // which is lazy-loaded when the rOute is visited.
    component: () => import('../views/Spaces/Spaces.vue'),
    children: [
      // {
      //   path: 'add',
      //   component: AddEvent
      // },
      // {
      //   path: 'edit/:slug',
      //   component: EditEvent
      // },
      {
        path: 'deletes',
        component: DeleteSpaces
      },
    ]
  },
  {
    path: '/users',
    name: 'users',
    // rOute level code-splittIng
    // this generates a separate chunk (about.[hash].js) for this rOute
    // which is lazy-loaded when the rOute is visited.
    component: () => import('../views/Users/Users.vue'),
    children: [
      // {
      //   path: 'add',
      //   component: AddEvent
      // },
      // {
      //   path: 'edit/:slug',
      //   component: EditEvent
      // },
      {
        path: 'reset-password',
        component: () => import('../views/Users/Actions/ResetPassword.vue'),
      },
      {
        path: 'deletes',
        component: DeleteUsers,
      },
    ]
  },
  {
    path: '/devices',
    name: 'devices',
    component: () => import('../views/Devices/Devices.vue'),
    children: [
      {
        path: 'add',
        component: AddDevice
      },
      {
        path: 'edit/:id',
        component: EditDevice
      },
      {
        path: 'delete/:id',
        component: DeleteDevice
      },
      {
        path: 'deletes',
        component: DeleteDevices
      },
      {
        path: 'assign',
        component: AssignDevices
      },
      {
        path: 'receive',
        component: ReceiveDevices
      },
      {
        path: 'change-base',
        component: ChangeDevicesBase
      },
      {
        path: 'change-devices-model',
        component: ChangeDevicesModel
      },
      {
        path: 'change-status',
        component: ChangeDevicesStatus
      },
      {
        path: 'export',
        component: ExportDevices
      },
      {
        path: 'import',
        component: ImportDevices
      },
      {
        path: 'models',
        name: 'deviceModels',
        component: () => import('../views/DeviceModels/DeviceModels.vue'),
        children: [
          {
            path: 'add',
            component: AddDeviceModel
          },
          {
            path: 'edit/:id',
            component: EditDeviceModel
          },
          {
            path: 'delete/:id',
            component: DeleteDeviceModel
          },
          {
            path: 'change-category',
            component: ChangeDeviceModelsCategory
          },
          {
            path: 'deletes',
            component: DeleteDeviceModels
          },
        ]
      },
      {
        path: 'categories',
        name: 'deviceCategories',
        component: () => import('../views/DeviceCategories/DeviceCategories.vue'),
        children: [
          {
            path: 'add',
            component: AddDeviceCategory
          },
          {
            path: 'edit/:id',
            component: EditDeviceCategory
          },
          {
            path: 'delete/:id',
            component: DeleteDeviceCategory
          },
          {
            path: 'deletes',
            component: DeleteDeviceCategories
          },
        ]
      },
    ]
  },

  // {
  //   path: '/users',
  //   name: 'users',
  //   // rOute level code-splittIng
  //   // this generates a separate chunk (about.[hash].js) for this rOute
  //   // which is lazy-loaded when the rOute is visited.
  //   component: () => import('../views/Users/Users.vue'),
  //   children: [
  //     {
  //       path: 'add',
  //       component: AddUser
  //     },
  //     {
  //       path: 'edit/:email',
  //       component: EditUser
  //     },
  //     {
  //       path: 'delete/:email',
  //       component: DeleteUser
  //     },
  //   ]
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  // {
  //   path: '/search/:query',
  //   name: 'search',
  //   component: () => import('../views/Search.vue')
  // },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/Logout.vue')
  },

  // {
  //   path: '/devices-wizard',
  //   name: 'devicesWizard',
  //   component: () => import('../views/DevicesWizard/DevicesWizard.vue'),
  //   children: [
  //     {
  //       path: '/',
  //       component: DevicesDirection
  //     },
  //     {
  //       path: 'in/location',
  //       component: InDevicesLocation
  //     },
  //     {
  //       path: 'out/location',
  //       component: OutDevicesLocation
  //     },
  //     {
  //       path: 'in/events',
  //       component: InDevicesEvents
  //     },
  //     {
  //       path: 'out/events',
  //       component: OutDevicesEvents
  //     },
  //     {
  //       path: 'in/devices',
  //       component: InDevices
  //     },
  //     {
  //       path: 'out/assign',
  //       component: OutDevicesAssign
  //     },
  //     {
  //       path: 'in/review',
  //       component: InDevicesReview
  //     },
  //     {
  //       path: 'in/completed',
  //       component: InDevicesCompleted
  //     },
  //     {
  //       path: 'out/assign/automatic',
  //       component: OutDevicesAssignAuto
  //     },
  //     {
  //       path: 'out/assign/manual',
  //       component: OutDevicesAssignManual
  //     },
  //     {
  //       path: 'out/review',
  //       component: OutDevicesReview
  //     },
  //     {
  //       path: 'out/completed',
  //       component: OutDevicesCompleted
  //     }
  //   ]
  // }
];

const router = new VueRouter({
  routes,
  mode: 'history',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active'
});

export default router;
