





















import { Component, Prop, Vue } from 'vue-property-decorator';

import { IDropdown } from './';

@Component({
  name: 'Dropdown'
})
class Dropdown extends Vue {
  @Prop() public config!: IDropdown;
  public isActive = false; public openClick = true;
  public created() {
    this.isActive = this.config.isActive ? true : false;

    if (this.config.isActive === undefined) {
      this.config.isActive = false;
    }
  }
  public open() {
    this.isActive = true;
  }

  public close() {
    this.openClick = false;

    if (this.config.close) {
      this.config.close();
    } else {
      if (this.config.onClose) {
        this.config.onClose();
      }

      this.isActive = false;
    }
  }
}

export default Dropdown;
