import { ISpace } from '@/models/space';
import axios from 'axios';

import { Resolve } from './';
import config from './config';

interface IData<T> {
  results: T[];
  item_count: number;
  page_count: number;
  current: number;
  next: number;
  previous: number;
}

const url = process.env.VUE_APP_SERVER_PATH;

export const getSpaces = (page = 1, pageSize = 20, search = undefined as undefined | string) => new Promise((resolve: Resolve<IData<ISpace>>, reject) => {
  axios
    .get(`${url}/v2/spaces?page_size=${pageSize}&page=${page}${search ? `&search=${encodeURIComponent(search)}` : ''}`, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const addSpace = (space: ISpace) => new Promise((resolve: Resolve<ISpace>, reject) => {
  axios
    .post(`${url}/spaces`, space, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const editSpace = (space: ISpace) =>
  new Promise((resolve: Resolve<ISpace[]>, reject) => {
    axios
      .put(`${url}/spaces/${space.id}`, space, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const deleteSpace = (space: ISpace) =>
  new Promise((resolve: Resolve<ISpace[]>, reject) => {
    axios
      .delete(`${url}/v2/spaces/${space.id}`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
