import { IDropdown, IModal, IOffcanvas, IPopup } from '@/components';
import { IInput } from '@/components/form';
import { ITable } from '@/components/table_v2';
import router from '@/router';
import { IStateUser } from '@/store/modules/users';
import { IUsersTranslations } from '@/translations';

export const table: (translations: IUsersTranslations) => ITable<IStateUser> = translations => ({

  id: 'users-table',
  columns: [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'name',
      label: translations.name
    },
    {
      key: 'email',
      label: translations.email
    },
    {
      key: 'phone_number',
      label: translations.phone
    },
  ],

  order: {
    id: 'desc'
  },

  actions: [{
    label: 'Reset password',
    icon: 'lock',
    position: 'bulk',
    single: true,
    route: {
      name: '/users/reset-password'
    }
  },
  {
    label: 'Delete user',
    icon: 'delete',
    position: 'bulk',
    route: {
      name: '/users/deletes',
      param: 'id'
    }
  }],
  visibleFields: {
    id: true,
    name: true,
    email: true,
    phone_number: true,
  },
  searchFields: {
    email: true,
    phone_number: true,
  },
  controls: true,
  refreshAction: 'fetchUsers',
  selectionGetter: 'getSelectedUsers'
});

export const usersModal: IModal = {
  isActive: true,
  close: () => {
    router.push('/users');
  }
};

export const usersOffcanvas: IOffcanvas = {
  isActive: true,
  close: () => {
    router.push('/users');
  }
};

export const eventActionPopup: IPopup = {
  message: '',
  type: '',
  isActive: false
};

export const eventsModal: IModal = {
  isActive: true,
  close: () => {
    router.push('/events');
  }
};

export const userModel: Partial<IStateUser> = {
  name: '',
};

export const dropdown: IDropdown = {
  isActive: false,
  position: 'right'
};

export const userActionPopup: IPopup = {
  message: '',
  type: '',
  isActive: false
};
