
import { ICommonTranslations } from '..';

const common: ICommonTranslations = {
  dashboard: 'Dashboard',
  events: 'Events',
  devices: 'Devices',
  logout: 'Logout',
  edit: 'Edit',
  create: 'Create',
  delete: 'Delete',
  name: 'Name',
  email: 'Email',
  description: 'Description',
  category: 'Category',
  model: 'Model',
  save: 'Save',
  download: 'Download',
  upload: 'Upload',
  change: 'Change',
  apply: 'Apply',
  enabled: 'Enabled',
  disabled: 'Disabled',
  users: 'Usuarios',
};

export default common;
