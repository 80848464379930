











































































































































































import { getHeadCounterEmails } from '@/api/headcounter';
import router from '@/router';
import { IDashboardTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// @ is an alias to /src
@Component({
  name: 'Home',
})
class Home extends Vue {
  @Getter('getDashboardTranslations')
  public translations!: IDashboardTranslations;
  public created() {}
  public getHeadCounterEmails() {
    getHeadCounterEmails().then((response) => {
      const a = document.createElement('a');
      a.href = response.data.file;
      a.target = '_blank';
      a.click();
    });
  }
}

export default Home;
