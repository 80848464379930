

















// @ is an alias to /src
import { ISelect } from '@/components/form';
import Select from '@/components/form/select.vue';
import Modal from '@/components/modal.vue';
import { IDeviceCategory } from '@/models/deviceCategory';
import router from '@/router';
import store from '@/store';
import { IStateDeviceCategory } from '@/store/modules/deviceCategories';
import { IStateDevice } from '@/store/modules/devices';
import { IDeviceCategoriesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import { deviceCategoryModal } from './../DeviceCategories.data';

@Component({
  name: 'DeleteDeviceCategories',
  components: { Modal, Select }
})
class DeleteDeviceCategories extends Vue {
  @Action('deleteDeviceCategory') public delete!: (device: IStateDeviceCategory) => void;

  @Getter('getSelectedDeviceCategories') public selected!: number[];

  @Getter('getDeviceCategories') public deviceCategories!: IStateDeviceCategory[];

  @Getter('getDeviceCategoriesTranslations') public translations!: IDeviceCategoriesTranslations;
  public modal = deviceCategoryModal;
  public created() {}
  public submit() {
    if (this.selected.length > 0) {
      this.selected.forEach(async id => {
        const deviceCategory = this.deviceCategories.find(
          deviceCategory => deviceCategory.id === id
        );
        if (deviceCategory) {
         this.delete(deviceCategory);
       }
      });
    }
    router.push('/devices/categories');
  }
  public close() {
    router.push('/devices/categories');
  }
}

export default DeleteDeviceCategories;
