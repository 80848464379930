import { IAuthTranslations } from '..';

const auth: IAuthTranslations = {
  welcome: 'Welcome',
  email: 'Email',
  email_placeholder: 'Enter your email',
  password: 'Password',
  password_placeholder: 'Enter a password',
  login: 'Login',
  log_back: 'Log back in?',
  logged_out: "You've been logged out",
  or: 'or',
  other_account: 'Other account',
};

export default auth;
