import Doughnut from '@/components/doughnut.vue';
import router from '@/router';
import { IDashboardTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'Dashboard',
  components: { Doughnut }
})
class Dashboard extends Vue {

  @Getter('getDashboardTranslations') public translations!: IDashboardTranslations;
  public dataLoaded = false; public router = router;
  public created() {

  }
  public beforeDestroy() {

  }
}

export default Dashboard;
