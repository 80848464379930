<template>
  <vuedraggable v-model="config.array" @change="config.change" :group="config.id" @start="drag=true" @end="drag=false">
    <!-- <div v-for="element in config.array" :key="element.id">{{element.name}}</div> -->
    <slot></slot>
  </vuedraggable>
</template>
<script>
// @ is an alias to /src
import vuedraggable from 'vuedraggable';
export default {
  name: 'Draggable',
  components: {
    vuedraggable
  },
  props: ['config'],
  data() {
    return {
      drag: false
    }
  },
  created() {
  }
}
</script>
<style lang="scss">
</style>
