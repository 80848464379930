

















// @ is an alias to /src
import { IModal } from '@/components';
import { ISelect } from '@/components/form';
import Select from '@/components/form/select.vue';
import Modal from '@/components/modal.vue';
import { IDeviceModel } from '@/models/deviceModel';
import router from '@/router';
import store from '@/store';
import { IStateDeviceModel } from '@/store/modules/deviceModels';
import { IBulkDevicesModel, IStateDevice } from '@/store/modules/devices';
import { IDevicesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

import { deviceForm, deviceModal } from '../';

@Component({
  name: 'ChangeDevicesModel',
  components: { Modal, Select }
})
class ChangeDevicesModel extends Vue {
  @Action('fetchDeviceModels') public fetch!: () => void;

  @Action('changeDevicesModel') public changeModel!: (devices: IBulkDevicesModel) => void;

  @Getter('getSelectedDevices') public selectedDevices!: number[];

  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public deviceModel = '';
  public modal = deviceModal;
  public selectModel: ISelect = {
    id: 'select-device-model',
    default: 'Select device model',
    value: '',
    options: []
  };
  public created() {
    this.fetch();

    const unsubscribe = store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setDeviceModels') {
        this.selectModel = {
         ...this.selectModel,
         options: mutation.payload.map((deviceModel: IDeviceModel) => ({
            value: deviceModel.id,
            label: deviceModel.name
          }))
       };

        unsubscribe();
      }
    });
  }
  public submit() {
    if (this.deviceModel !== '') {
      this.changeModel({
        ids: this.selectedDevices,
        model_type_id: parseInt(this.deviceModel, 10)
      });
      router.push('/devices');
    }
  }
  public close() {
    router.push('/devices');
  }
}

export default ChangeDevicesModel;
