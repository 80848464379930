
















// @ is an alias to /src
import Modal from '@/components/modal.vue';
import router from '@/router';
import { IUsersTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { usersModal } from '../';

@Component({
  name: 'DeleteUsers',
  components: { Modal },
})
class DeleteUsers extends Vue {
  @Action('deleteUsers') public delete!: (ids: number[]) => void;

  @Getter('getSelectedUsers') public selectedUsers!: number[];

  @Getter('getUsersTranslations') public translations!: IUsersTranslations;

  public modal = usersModal;

  public submit() {
    this.delete(this.selectedUsers);
    router.push('/users');
  }

  public close() {
    router.push('/users');
  }
}

export default DeleteUsers;
