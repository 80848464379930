import { IDeviceModelsTranslations } from '..';
import common from '../common/pt';

const deviceModels: IDeviceModelsTranslations = {
  ...common,
  device_models: 'Modelos de dispositivo',
  change_model: 'Alterar categoria do modelo de dispositivo',
  edit_device_model: 'Editar modelo de dispositivo',
  delete_device_models: 'Eliminar modelos de dispositivo',
  deletes_confirm: 'Tem a certeza que quer eliminar estes modelos de dispositivo?',
  new_device_model: 'Novo modelo de dispositivo',
  change_category: 'Alterar categoria',
  device_model_name: 'Nome do modelo de dispositivo',
  device_model_name_placeholder: 'Digite o nome do modelo de dispositivo',
  device_model_description: 'Descrição do modelo de dispositivo',
  device_model_description_placeholder: 'Digite uma descrição para o modelo de dispositivo',
};

export default deviceModels;
