

















// @ is an alias to /src
import { IOffcanvas } from '@/components';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Textarea from '@/components/form/textarea.vue';
import Offcanvas from '@/components/offcanvas.vue';
import { IDeviceCategory } from '@/models/deviceCategory';
import router from '@/router';
import store from '@/store';
import { IStateDeviceCategory } from '@/store/modules/deviceCategories';
import { IDeviceCategoriesTranslations } from '@/translations';
import modelToForm from '@/utils/modelToForm';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import {
  deviceCategory,
  deviceCategoryOffcanvas,
  form,
  IDeviceCategoryForm
} from './../DeviceCategories.data';

@Component({
  name: 'EditDeviceCategory',
  components: { Offcanvas, Input, Textarea, Select }
})
class EditDeviceCategory extends Vue {
  @Action('fetchDeviceCategories') public fetch!: () => void;

  @Action('editDeviceCategory') public edit!: (deviceCategory: IStateDeviceCategory) => void;

  @Getter('getDeviceCategories') public deviceCategories!: IStateDeviceCategory[];

  @Getter('getDeviceCategoriesTranslations') public translations!: IDeviceCategoriesTranslations;
  public offcanvas = deviceCategoryOffcanvas;

  /*
    Spread operator used to prevent values to propagate to other views using
    the same form data
  */ public form!: IDeviceCategoryForm;
  public deviceCategory = { ...deviceCategory };
  public id = 0;
  public created() {
    this.form = form(this.translations);

    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id, 10);
      if (this.deviceCategories.length > 0) {
        this.findAndAssign();
      } else {
        this.fetch();
        const unsubscribe = store.subscribe(
          (setDevices: { payload: IStateDeviceCategory[] }) => {
            if (setDevices.payload instanceof Array) {
              this.deviceCategories = setDevices.payload;
              this.findAndAssign();
              unsubscribe();
            }
          }
        );
      }
    }
  }
  public findAndAssign() {
    const deviceCategory = this.deviceCategories.find(
      deviceCategory => deviceCategory.id === this.id
    );
    if (deviceCategory) {
      this.deviceCategory = deviceCategory;
      this.form = modelToForm(this.deviceCategory, this.form);
    }
  }
  public submit() {
    if (this.deviceCategory) {
      this.edit(this.deviceCategory);
      router.push('/devices/categories');
    }
  }
  public discard() {
    router.push('/devices/categories');
  }
}

export default EditDeviceCategory;
