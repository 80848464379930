import {
  addDevice,
  assignDevices,
  changeDevicesBase,
  changeDevicesModel,
  changeDevicesStatus,
  deleteDevice,
  deleteDevices,
  editDevice,
  getDevices
} from '@/api/devices';
import { IDevice } from '@/models/device';
import { IDeviceModel } from '@/models/deviceModel';
import formatDate from '@/utils/formatDate';
import { ActionContext } from 'vuex';

import store, { IState } from '..';
import { IObjectState, IStateObject } from '../';

export interface IStateDevice extends IObjectState, IDevice {

}

export interface IDevicesState extends IStateObject<IStateDevice> {
  selected: number[];
}

export interface IBulkDevices {
  ids: number[];
}

export interface IBulkDevicesBase extends IBulkDevices {
  base: string;
}

export interface IBulkDevicesStatus extends IBulkDevices {
  status: string;
}

export interface IBulkDevicesEvent extends IBulkDevices {
  current_event_id: number | null;
  event_name?: string;
}

export interface IBulkDevicesModel extends IBulkDevices {
  model_type_id: number;
}

const state: IDevicesState = {
  list: [],
  selected: [],
  loading: false,
  message: '',
  error: false
};

const addStateProps = (devices: IDevice[]) =>
  devices.map(device => ({
    ...device,
    loading: false,
    error: false,
    message: ''
  }));

const getters = {
  getDevices: (state: IDevicesState) => state.list,
  getSelectedDevices: (state: IDevicesState) => state.selected,
  getDevicesState: (state: IDevicesState) => state,
};

const actions = {

  fetchDevices(
    context: ActionContext<IDevicesState, IState>
  ) {

    context.commit('setDevicesLoading');

    getDevices()
      .then(response => {
        context.commit('setDevices', addStateProps(response.data));
      })
      .catch(err => {
        context.commit('setDevicesError', err.message);
      });

  },

  addDevice(context: ActionContext<IDevicesState, IState>, device: IDevice) {
    context.commit('setDevicesLoading');

    addDevice(device)
      .then(response => {
        context.commit('newDevice', addStateProps([response.data])[0]);
      })
      .catch(err => {
        context.commit('setDevicesError', err.response.data);
      });

  },

  editDevice(context: ActionContext<IDevicesState, IState>, editedDevice: IStateDevice) {
    context.commit('setLoadingDevice', context.state.list.find(device => editedDevice.id === device.id));

    editDevice(editedDevice)
      .then(response => {
        context.commit('editDevice', response.data);
      })
      .catch(err => {
        context.commit('setDevicesError', err.response.data);
      });

  },
  deleteDevice(context: ActionContext<IDevicesState, IState>, deletedDevice: IStateDevice) {
    context.commit('setLoadingDevice', context.state.list.find(device => deletedDevice.id === device.id));

    deleteDevice(deletedDevice)
      .then(response => {
        context.commit('removeDevice', deletedDevice);
      })
      .catch(err => {
        context.commit('setDeviceError', { failedDevice: deletedDevice, message: err.message });
      });

  },

  deleteDevices(context: ActionContext<IDevicesState, IState>, ids: number[]) {

    context.commit('setLoadingDevices', ids);

    deleteDevices(ids)
      .then(response => {
        context.commit('removeDevices', ids);
      })
      .catch(err => {
        for (const deviceId of ids) {
          context.commit('setDeviceError', {
            failedDevice: {
              id: deviceId
            }, message: err.message
          });
        }
      });

  },

  assignDevices(context: ActionContext<IDevicesState, IState>, devices: IBulkDevicesEvent) {

    context.commit('setLoadingDevices', devices.ids);

    assignDevices(devices)
      .then(response => {
        context.commit('setDevicesEvent', devices);

      }).catch(err => {
        for (const deviceId of devices.ids) {
          context.commit('setDeviceError', {
            failedDevice: {
              id: deviceId
            }, message: err.message
          });
        }
      });

  },

  changeDevicesLocation(context: ActionContext<IDevicesState, IState>, devices: IBulkDevicesBase) {
    context.commit('setLoadingDevices', devices.ids);

    changeDevicesBase(devices)
      .then(response => {
        context.commit('setDevicesLocation', devices);
      })
      .catch(err => {
        for (const deviceId of devices.ids) {
          context.commit('setDeviceError', {
            failedDevice: {
              id: deviceId
            }, message: err.message
          });
        }
      });
  },
  changeDevicesModel(context: ActionContext<IDevicesState, IState>, devices: { ids: number[], model_type_id: number }) {

    context.commit('setLoadingDevices', devices.ids);

    changeDevicesModel(devices)
      .then(response => {
        context.commit('setDevicesModel', devices);
      })
      .catch(err => {
        for (const deviceId of devices.ids) {
          context.commit('setDeviceError', {
            failedDevice: {
              id: deviceId
            }, message: err.message
          });
        }
      });
  },

  changeDevicesStatus(context: ActionContext<IDevicesState, IState>, devices: IBulkDevicesStatus) {
    context.commit('setLoadingDevices', devices.ids);

    changeDevicesStatus(devices)
      .then(response => {
        context.commit('setDevicesStatus', devices);
      })
      .catch(err => {
        for (const deviceId of devices.ids) {
          context.commit('setDeviceError', {
            failedDevice: {
              id: deviceId
            }, message: err.message
          });
        }
      });
  }

};

const mutations = {

  setDevicesError: (state: IDevicesState, message: string) => (
    (state.loading = false),
    (state.error = true),
    (state.message = message)
  ),
  setDeviceError: (state: IDevicesState, { failedDevice, message }: { failedDevice: IStateDevice, message: string }) => (
    (state.list = state.list.map(device => device.id === failedDevice.id ? {
      ...device,
      loading: false,
      error: true,
      message
    } : device))
  ),

  setDevicesLoading: (state: IDevicesState) => (
    (state.loading = true),
    (state.error = false)
  ),

  setLoadingDevice: (state: IDevicesState, device: IStateDevice) => (
    (state.list[state.list.indexOf(device)] = {
      ...state.list[state.list.indexOf(device)],
      loading: true,
      error: false
    })
  ),

  setLoadingDevices: (state: IDevicesState, ids: number[]) => (
    (state.list = state.list.map(
      device => ({
        ...device,
        loading: device.id && ids.indexOf(device.id) > -1 ? true : false,
        error: false
      })
    ))
  ),

  setDevicesEvent: (state: IDevicesState, assignedDevices: IBulkDevicesEvent) => (
    (state.list = state.list.map(
      device => ({
        ...device,
        current_event_name: device.id && assignedDevices.ids.indexOf(device.id) > -1 ? assignedDevices.event_name : device.current_event_name,
        loading: false,
        error: false
      })
    ))
  ),

  setDevicesLocation: (state: IDevicesState, changedDevices: any) => (
    (state.list = state.list.map(
      device => ({
        ...device,
        base: device.id && changedDevices.ids.indexOf(device.id) > -1 ? changedDevices.base : device.base,
        loading: false,
        error: false
      })
    ))
  ),
  setDevicesStatus: (state: IDevicesState, changedDevices: any) => (
    (state.list = state.list.map(
      device => ({
        ...device,
        status: device.id && changedDevices.ids.indexOf(device.id) > -1 ? changedDevices.status : device.status,
        loading: false,
        error: false
      })
    ))
  ),
  setDevicesModel: (state: IDevicesState, changedDevices: any) => {
    const deviceModel = store.getters.getDeviceModels.find((deviceModel: IDeviceModel) => deviceModel.id === changedDevices.model_type_id);
    state.list = state.list.map(
      device => ({
        ...device,
        model_name: changedDevices.ids.indexOf(device.id) > -1 ? deviceModel.name : device.model_name,
        category_name: changedDevices.ids.indexOf(device.id) > -1 ? deviceModel._category.name : device.category_name,
        loading: false,
        error: false
      })
    );

  },

  setDevices: (state: IDevicesState, devices: IStateDevice[]) => (
    (state.error = false),
    (state.loading = false),
    (state.list = devices)
  ),

  selectDevice: (state: IDevicesState, device: IStateDevice) => {
    if (device.id) {
      const deviceIndex = state.selected.indexOf(device.id);
      if (deviceIndex > -1) {
        state.selected.splice(deviceIndex, 1);
      } else {
        state.selected.push(device.id);
      }
    }
  },

  editDevice: (state: IDevicesState, editedDevice: IStateDevice) => (
    (state.list = state.list.map(
      device => device.id === editedDevice.id ? {
        ...editedDevice,
        loading: false,
        error: false
      } : device
    ))
  ),

  newDevice: (state: IDevicesState, device: IStateDevice) => (
    (state.loading = false),
    (state.error = false),
    state.list.unshift({
      ...device,
      created_at: formatDate(device.created_at ? device.created_at : ''),
      updated_at: formatDate(device.updated_at ? device.updated_at : ''),
    })
  ),

  removeDevice: (state: IDevicesState, deletedDevice: IStateDevice) =>
    (
      state.list = state.list.filter(device => device.id !== deletedDevice.id)
    ),
  removeDevices: (state: IDevicesState, ids: number[]) =>
    (
      state.list = state.list.filter(device => device.id && ids.indexOf(device.id) === -1)
    ),

};

export default {
  state,
  getters,
  actions,
  mutations
};
