import { IDeviceModelsTranslations } from '..';
import common from '../common/es';

const deviceModels: IDeviceModelsTranslations = {
  ...common,
  device_models: 'Device models',
  deletes_confirm: 'Are you sure you want to delete these device models?',
  change_model: 'Change device model category',
  delete_device_models: 'Delete device models',
  edit_device_model: 'Edit device model',
  new_device_model: 'New device model',
  change_category: 'Change category',
  device_model_name: 'Device model name',
  device_model_name_placeholder: 'Enter device model name',
  device_model_description: 'Device model description',
  device_model_description_placeholder: 'Enter device model description',
};

export default deviceModels;
