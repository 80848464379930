

















// @ is an alias to /src
import { ISelect } from '@/components/form';
import Select from '@/components/form/select.vue';
import Modal from '@/components/modal.vue';
import router from '@/router';
import store from '@/store';
import {
  IBulkDevicesBase,
  IBulkDevicesEvent,
  IStateDevice
} from '@/store/modules/devices';
import { IDevicesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import { deviceModal } from './../';

@Component({
  name: 'ChangeDevicesBase',
  components: { Modal, Select }
})
class ChangeDevicesBase extends Vue {
  @Action('changeDevicesLocation') public changeLocation!: (devices: IBulkDevicesBase) => void;

  @Getter('getSelectedDevices') public selectedDevices!: number[];

  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public base = '';
  public modal = deviceModal;
  public selectBase: ISelect = {
    id: 'select-base',
    default: 'Select base',
    value: '',
    options: [
      {
        value: 'anadia',
        label: 'Anadia'
      },
      {
        value: 'oporto',
        label: 'Porto'
      },
      {
        value: 'lisbon',
        label: 'Lisbon'
      },
      {
        value: 'vision volt',
        label: 'VisionVolt'
      }
    ]
  };
  public created() {}
  public submit() {
    if (this.base !== '') {
      this.changeLocation({
        ids: this.selectedDevices,
        base: this.base
      });

      router.push('/devices');
    }
  }
  public close() {
    router.push('/devices');
  }
}

export default ChangeDevicesBase;
