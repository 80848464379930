







// @ is an alias to /src
import Navbar from '@/components/navbar.vue';
import router from '@/router';
import store from '@/store';
import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'App',
  components: {
    Navbar,
  },
})
class App extends Vue {
  @Getter('userLoggedIn') public userLoggedIn!: boolean;
  public created() {
    const localStorageLang = process.env.VUE_APP_LOCAL_STORAGE_LANG_PROP;
    const lang = localStorage.getItem(localStorageLang);

    store.commit('setLanguage', lang ? lang : 'en');

    const localStorageAuthProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
    const authString = localStorage.getItem(localStorageAuthProp);

    if (authString) {
      const { userLoggedIn, auth } = JSON.parse(authString);

      if (userLoggedIn) {
        store.commit('setUserLogin', auth);
      } else {
        router.push('/login');
      }
    } else {
      router.push('/login');
    }
    const serverPath = process.env.VUE_APP_SERVER_PATH;
    axios.interceptors.response.use(undefined, (err) => {
      if (err.request.responseURL.includes(serverPath)) {
        if (err.request.status === 401) {
          // if you ever get an unauthorized, logout the user
          localStorage.setItem(
            process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
            router.currentRoute.fullPath
          );
          router.push('/login');
          // this.addPopup({
          //   message: err.message,
          //   type: 'danger',
          //   autohide: true,
          // });
        }
        if (err.request.status === 403) {
          // User should not see permissions error
          router.push('/login');
        }
      }
      throw err;
    });
  }
}

export default App;
