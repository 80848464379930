


















import Scrollable from '@/components/scrollable.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { IModal } from './';

@Component({
  name: 'Modal',
  components: { Scrollable }
})
class Modal extends Vue {
  @Prop({ required: true }) public config!: IModal;
  public created() {}
  public close() {
    if (this.config.close) {
      this.config.close();
    } else {
      if (this.config.onClose) {
        this.config.onClose();
      }

      this.config.isActive = false;
    }
  }
}

export default Modal;
