










































// @ is an alias to /src
import { uploadEventImage } from '@/api/events';
import { IModal } from '@/components';
import { ICheckbox, IInput, ISelect, ITextarea } from '@/components/form';
import Checkbox from '@/components/form/checkbox.vue';
import DatePicker from '@/components/form/date-picker.vue';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Textarea from '@/components/form/textarea.vue';
import VueSelect from '@/components/form/vue-select/vue-select.vue';
import Modal from '@/components/modal.vue';
import { IEvent } from '@/models/event';
import router from '@/router';
import store, { IObjectState, IState, IStateObject } from '@/store';
import config from '@/store/modules/config';
import { IStateEvent } from '@/store/modules/events';
import { IStateUser } from '@/store/modules/users';
import { IEventsTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import { eventModel, eventsModal, form, IEventForm } from './../';

@Component({
  name: 'AddEvent',
  components: { Input, Checkbox, Textarea, Select, Modal, DatePicker, VueSelect }
})
class AddEvent extends Vue {
  @Action('addEvent') public add!: (event: Partial<IEvent>) => void;

  @Action('uploadEventImage') public upload!: (file: File) => void;

  @Getter('getEventsTranslations') public translations!: IEventsTranslations;
  public modal = eventsModal;
  public image: File | null = null;
  public backgroundImage: File | null = null;
  public eventManager: number | null = null;
  public form!: IEventForm;
  public event = eventModel;
  public created() {
    this.form = form(this.translations);
  }
  public async submit() {
    const imageForm = new FormData();
    const backgroundImageForm = new FormData();

    if (this.image) {
      imageForm.append('file', this.image);
      const imageResponse = await uploadEventImage(imageForm);
      this.event.image = imageResponse.data.id;
    } else {
      delete this.event.image;
    }

    if (this.backgroundImage) {
      backgroundImageForm.append('file', this.backgroundImage);
      const backgroundImageResponse = await uploadEventImage(
        backgroundImageForm
      );
      this.event.background_image = backgroundImageResponse.data.id;
    } else {
      delete this.event.background_image;
    }

    this.add(this.event);

    const unsubscribe = store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setEventsError') {
        const errors = mutation.payload;
        const errorKeys = Object.keys(errors) as Array<Extract<IEvent, string>>;
        const form = {} as any;

        errorKeys.forEach(key => {
         form[key] = {
            ...(this.form[key] as object),
            error: true,
            message: errors[key][0]
          };
       });

        this.form = { ...this.form, ...form };
      }

      if (mutation.type === 'newEvent') {
        router.push('/events');
        unsubscribe();
      }
    });
  }
  public discard() {
    router.push('/events');
  }
}

export default AddEvent;
