import { IAuth, IUserAuth } from '@/models/auth';
import axios from 'axios';

import { Resolve } from './';
import config from './config';

const url = process.env.VUE_APP_SERVER_PATH;

export const loginUser = (user: IUserAuth) =>
  new Promise((resolve: Resolve<IAuth>, reject) => {
    axios
      .post(`${url}/auth`, user, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const isAdmin = () =>
  new Promise((resolve: Resolve<IAuth>, reject) => {
    axios
      .get(`${url}/v2/services/is-admin`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
