
















// @ is an alias to /src
import Modal from '@/components/modal.vue';
import router from '@/router';
import store from '@/store';
import { IStateDeviceCategory } from '@/store/modules/deviceCategories';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { deviceCategoryModal } from './../DeviceCategories.data';

@Component({
  name: 'DeleteDeviceCategory',
  components: { Modal }
})
class DeleteDeviceModel extends Vue {
  @Action('fetchDeviceCategoriess') public fetch!: () => void;

  @Getter('getDeviceCategories') public deviceCategories!: IStateDeviceCategory[];

  @Action('deleteDeviceCategory') public delete!: (deviceCategory: IStateDeviceCategory) => void;
  public modal = deviceCategoryModal;
  public deviceCategory!: IStateDeviceCategory;
  public id = 0;
  public created() {
    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id, 10);
    }
    if (this.deviceCategories.length > 0) {
      this.findAndAssign();
    } else {
      this.fetch();
      store.subscribe((setDevices: { payload: IStateDeviceCategory[] }) => {
        if (setDevices.payload instanceof Array) {
         this.deviceCategories = setDevices.payload;
         this.findAndAssign();
       }
      });
    }
  }
  public findAndAssign() {
    const deviceCategory = this.deviceCategories.find(
      deviceCategory => deviceCategory.id === this.id
    );
    if (deviceCategory) {
      this.deviceCategory = deviceCategory;
    }
  }
  public submit() {
    if (this.deviceCategory) {
      this.delete(this.deviceCategory);
      router.push('/devices/categories');
    }
  }
  public close() {
    router.push('/devices/categories');
  }
}

export default DeleteDeviceModel;
