import { IDeviceModel } from '@/models/deviceModel';
import axios from 'axios';

import { Resolve } from './';
import config from './config';

export interface IDeviceModelsCategory {
  ids: number[];
  category_id: number;
}

const url = process.env.VUE_APP_SERVER_PATH;

export const getDeviceModels = () =>
  new Promise((resolve: Resolve<IDeviceModel[]>, reject) => {

    axios
      .get(`${url}/admin/device-models?page_size=500`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });

  });

export const addDeviceModel = (deviceModel: IDeviceModel) =>
  new Promise((resolve: Resolve<IDeviceModel>, reject) => {

    axios
      .post(`${url}/admin/device-models`, deviceModel, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });

  });

export const editDeviceModel = (deviceModel: IDeviceModel) =>
  new Promise((resolve: Resolve<IDeviceModel>, reject) => {

    axios
      .put(`${url}/admin/device-models/${deviceModel.id}`, deviceModel, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });

  });

export const deleteDeviceModel = (deviceModel: IDeviceModel) =>
  new Promise((resolve: Resolve<string>, reject) => {

    axios
      .delete(`${url}/admin/device-models/${deviceModel.id}`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });

  });

export const changeDeviceModelsCategory = (devices: IDeviceModelsCategory) =>
  new Promise((resolve: Resolve<IDeviceModelsCategory>, reject) => {

    axios
      .put(`${url}/admin/device-models/category`, devices, config())
      .then(response => {

        resolve({ ...response, data: devices });

      })
      .catch(err => {
        reject(err);
      });

  });
