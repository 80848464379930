
































// @ is an alias to /src
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { ISelect } from './';

@Component({
  name: 'Select'
})
class Select extends Vue {
  @Prop({ required: true }) public config!: ISelect;
  @Prop({ required: true }) public value!: string;

  @Watch('config') public onConfigChanges() {
    this.$emit('input', this.config.value);
  } public created() {
    this.$emit('input', this.config.value);
  }
  public updateValue(value: string | number) {
    this.$emit('input', value);
  }
}
export default Select;
