import { IDevice } from '@/models/device';
import store from '@/store';
import { IStateEvent } from '@/store/modules/events';
import axios from 'axios';
import { MutationPayload } from 'vuex';

import { Resolve } from './';
import config from './config';

interface IAssignDevices {
  ids: number[];
  current_event_id?: number | null;
  event_name?: string | null;
}
interface IDevicesBase {
  ids: number[];
  base: string;
}
interface IDevicesModel {
  ids: number[];
  model_type_id: number;
}
interface IDevicesStatus {
  ids: number[];
  status: string;
}

const url = process.env.VUE_APP_SERVER_PATH;

export const getDevices = () => new Promise((resolve: Resolve<IDevice[]>, reject) => {
  axios
    .get(`${url}/admin/devices?page_size=1000`, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const addDevice = (device: IDevice) => new Promise((resolve: Resolve<IDevice>, reject) => {
  axios
    .post(`${url}/admin/devices`, device, config())
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
});

export const editDevice = (device: IDevice) =>
  new Promise((resolve: Resolve<IDevice>, reject) => {
    axios
      .put(`${url}/admin/devices/${device.id}`, device, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });

  });

export const deleteDevice = (device: IDevice) =>
  new Promise((resolve: Resolve<IDevice>, reject) => {

    axios
      .delete(`${url}/admin/devices/${device.id}`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });

  });

export const deleteDevices = (ids: number[]) =>
  new Promise((resolve: Resolve<IDevice>, reject) => {

    axios
      .delete(`${url}/admin/devices/bulk_delete?ids=${ids.join('&ids=')}`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });

  });

export const assignDevices = (assignDevices: IAssignDevices) =>
  new Promise((resolve: Resolve<IAssignDevices>, reject) => {

    axios
      .put(`${url}/admin/devices/current_event`, assignDevices, config())
      .then(response => {
        const events: IStateEvent[] = store.getters.getEvents;
        if (assignDevices.current_event_id === null) {

          resolve({
            ...response,
            data: {
              ids: assignDevices.ids,
              event_name: null
            }
          });

        } else {
          if (events.length > 0) {

            const event = events.find(event => event.id === assignDevices.current_event_id);

            if (event) {

              resolve({
                ...response,
                data: {
                  ids: assignDevices.ids,
                  event_name: event.name
                }
              });

            }

          } else {

            store.dispatch('fetchEvents');

            store.subscribe((mutation: MutationPayload) => {

              if (mutation.type === 'setEvents') {

                const event = mutation.payload.find((event: IStateEvent) => event.id === assignDevices.current_event_id);

                if (event) {

                  resolve({
                    ...response,
                    data: {
                      ids: assignDevices.ids,
                      event_name: event.name
                    }
                  });

                }

              }
            });
          }
        }

      })
      .catch(err => {
        reject(err);
      });

  });
export const changeDevicesBase = (devices: IDevicesBase) =>
  new Promise((resolve: Resolve<IDevicesBase>, reject) => {

    axios
      .patch(`${url}/v1/services/devices-bulk-update-base`, devices, config())
      .then(response => {

        devices.base = devices.base === 'lisbon' ? 'Lisbon' : devices.base === 'vision bolt' ? 'Vision Bolt' : 'Porto';
        resolve({ ...response, data: devices });

      })
      .catch(err => {
        reject(err);
      });

  });

export const changeDevicesModel = (devices: IDevicesModel) =>
  new Promise((resolve: Resolve<IDevicesModel>, reject) => {

    axios
      .put(`${url}/admin/devices/model_type`, devices, config())
      .then(response => {

        resolve({ ...response, data: devices });

      })
      .catch(err => {
        reject(err);
      });

  });

export const changeDevicesStatus = (devices: IDevicesStatus) =>
  new Promise((resolve: Resolve<''>, reject) => {
    axios
      .patch(`${url}/v1/services/devices-bulk-update-status`, devices, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });

  });

export const exportDevices = () =>
  new Promise((resolve: Resolve<string>, reject) => {
    const { token } = store.getters.userAuthState;

    axios
      .get(`${url}/admin/devices/xlsx_export`,
      {
        headers: {
            // tslint:disable-next-line: object-literal-key-quotes
          'content-type': 'application/vnd.beamian+json; version=1',
          Authorization: `Token ${token}`,
          'vnd.beamian.role': 'admin',
        },
        responseType: 'arraybuffer',
      })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });

export const importDevices = (formData: FormData) =>
  new Promise((resolve: Resolve<File>, reject) => {
    axios
      .post(`${url}/admin/devices/xlsx_import`, formData, config(true))
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
