import { addDeviceModel, changeDeviceModelsCategory, deleteDeviceModel, editDeviceModel, getDeviceModels } from '@/api/deviceModels';
import { IDeviceCategory } from '@/models/deviceCategory';
import { IDeviceModel } from '@/models/deviceModel';
import { ActionContext } from 'vuex';

import store, { IState } from '..';
import { IObjectState, IStateObject } from '../';
/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateDeviceModel extends IObjectState, IDeviceModel {

}
export interface IDeviceModelsState extends IStateObject<IStateDeviceModel> {
  selected: number[];
}

const state: IDeviceModelsState = {
  list: [],
  selected: [],
  loading: false,
  message: '',
  error: false
};

const addStateProps = (deviceModels: IDeviceModel[]) =>
  deviceModels.map(deviceModel => ({
    ...deviceModel,
    loading: false,
    error: false,
    message: ''
  }));

const getters = {
  getDeviceModels: (state: IDeviceModelsState) => state.list,
  getSelectedDeviceModels: (state: IDeviceModelsState) => state.selected,
  getDeviceModelsLoading: (state: IDeviceModelsState) => state.loading,
  getDeviceModelsError: (state: IDeviceModelsState) => state.error,
  getDeviceModelsMessage: (state: IDeviceModelsState) => state.message,
};

const actions = {

  fetchDeviceModels(
    context: ActionContext<IDeviceModelsState, IState>
  ) {

    context.commit('setDeviceModelsLoading');

    getDeviceModels()
      .then(response => {
        context.commit('setDeviceModels', addStateProps(response.data));
      })
      .catch(err => {
        context.commit('setDeviceModelsError', err.message);
      });

  },

  addDeviceModel(context: ActionContext<IDeviceModelsState, IState>, device: IDeviceModel) {
    context.commit('setDeviceModelsLoading');

    addDeviceModel(device)
      .then(response => {
        context.commit('newDeviceModel', addStateProps([response.data])[0]);
      })
      .catch(err => {
        context.commit('setDeviceModelsError', err.message);
      });
  },

  editDeviceModel(context: ActionContext<IDeviceModelsState, IState>, editedDevice: IStateDeviceModel) {

    context.commit('setDeviceModelLoading', context.state.list.find(deviceModel => editedDevice.id === deviceModel.id));

    editDeviceModel(editedDevice)
      .then(response => {
        context.commit('editDeviceModel', response.data);
      })
      .catch(err => {
        context.commit('setDeviceError', { failedDevice: editedDevice, message: err.message });
      });

  },
  deleteDeviceModel(context: ActionContext<IDeviceModelsState, IState>, deletedDeviceModel: IStateDeviceModel) {

    context.commit('setDeviceModelLoading', context.state.list.find(deviceModel => deletedDeviceModel.id === deviceModel.id));

    deleteDeviceModel(deletedDeviceModel)
      .then(response => {
        context.commit('removeDeviceModel', deletedDeviceModel);
      })
      .catch(err => {
        context.commit('setDeviceError', { failedDevice: deletedDeviceModel, message: err.message });
      });

  },
  changeDeviceModelsCategory(context: ActionContext<IDeviceModelsState, IState>, deviceModelsCategory: { ids: number[], category_id: number }) {
    context.commit('setLoadingDeviceModels', deviceModelsCategory.ids);

    changeDeviceModelsCategory(deviceModelsCategory)
      .then(response => {

        const deviceCategories: IDeviceCategory[] = store.getters.getDeviceCategories;

        if (deviceCategories) {
          const currentCategory = deviceCategories.find(category => category.id === deviceModelsCategory.category_id);
          if (currentCategory) {
            context.commit('setDeviceModelsCategory', { ...response.data, category_name: currentCategory.name });
          }
        }

      })
      .catch(err => {
        context.commit('setDeviceModelsError', { ids: deviceModelsCategory.ids, message: err.message });
      });
  },
};

const mutations = {

  setDeviceModelsError: (state: IDeviceModelsState, message: string) => (
    (state.loading = false),
    (state.error = true),
    (state.message = message)
  ),
  setDeviceModelError: (state: IDeviceModelsState, { failedDeviceModel, message }: { failedDeviceModel: IStateDeviceModel, message: string }) => (
    (state.list = state.list.map(device => device.id === failedDeviceModel.id ? {
      ...device,
      loading: false,
      error: true,
      message
    } : failedDeviceModel))
  ),

  setDeviceModelsLoading: (state: IDeviceModelsState) => (
    (state.loading = true),
    (state.error = false)
  ),

  setDeviceModelLoading: (state: IDeviceModelsState, deviceModel: IStateDeviceModel) => (
    (state.list[state.list.indexOf(deviceModel)] = {
      ...state.list[state.list.indexOf(deviceModel)],
      loading: true,
      error: false
    })
  ),

  setLoadingDeviceModels: (state: IDeviceModelsState, ids: number[]) => (
    (state.list = state.list.map(
      deviceModel => ({
        ...deviceModel,
        loading: deviceModel.id && ids.indexOf(deviceModel.id) > -1 ? true : false,
        error: false
      })
    ))
  ),

  setDeviceModels: (state: IDeviceModelsState, deviceModels: IStateDeviceModel[]) => (
    (state.error = false),
    (state.loading = false),
    (state.list = deviceModels)
  ),

  editDeviceModel: (state: IDeviceModelsState, editedDeviceModel: IStateDeviceModel) => (
    (state.list = state.list.map(
      device => device.id === editedDeviceModel.id ? {
        ...editedDeviceModel,
        loading: false,
        error: false
      } : device
    ))
  ),

  newDeviceModel: (state: IDeviceModelsState, deviceModel: IStateDeviceModel) => (
    (state.loading = false),
    (state.error = false),
    state.list.unshift(deviceModel)
  ),

  removeDeviceModel: (state: IDeviceModelsState, deletedDevicModel: IDeviceModel) =>
    (
      state.list = state.list.filter(device => device.id !== deletedDevicModel.id)
    ),

  setDeviceModelsCategory: (state: IDeviceModelsState, changedDevices: any) => (
    (state.list = state.list.map(
      deviceModel => ({
        ...deviceModel,
        _category: deviceModel.id && changedDevices.ids.indexOf(deviceModel.id) > -1 ? {
          id: changedDevices.category_id,
          name: changedDevices.category_name,
        } : deviceModel._category,
        loading: false,
        error: false
      })
    ))
  ),
  selectDeviceModel: (state: IDeviceModelsState, deviceModel: IStateDeviceModel) => {
    if (deviceModel.id) {
      const deviceIndex = state.selected.indexOf(deviceModel.id);
      if (deviceIndex > -1) {
        state.selected.splice(deviceIndex, 1);
      } else {
        state.selected.push(deviceModel.id);
      }
    }
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
