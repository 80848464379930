
import { ISpacesTranslations } from '../';

const spaces: ISpacesTranslations = {
  name: 'Nome',
  spaces: 'Espaços',
  deleteSpaces: 'Apagar espaços',
  currentCount: 'Contagem atual',
  capacity: 'Capacidade',
  slug: 'Prefixo de URL',
  website: 'Website',
  facebook: 'URL de facebook',
  phone: 'Número de telemóvel',
  delete_spaces_confirm: 'Tem a certeza que quer apagar estes espaços?',
  user: 'Utilizador',
  updated_at: 'Ultima atualização',
};

export default spaces;
