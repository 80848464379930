














// @ is an alias to /src
import Modal from '@/components/modal.vue';
import router from '@/router';
import store from '@/store';
import { IStateDevice } from '@/store/modules/devices';
import { IDevicesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { deviceModal } from '../';

@Component({
  name: 'DeleteDevice',
  components: { Modal }
})
class DeleteDevice extends Vue {
  @Action('fetchDevices') public fetch!: () => void;

  @Getter('getDevices') public devices!: IStateDevice[];

  @Action('deleteDevice') public delete!: (device: IStateDevice) => void;

  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public modal = deviceModal;
  public device: IStateDevice | undefined;
  public id = 0;
  public created() {
    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id, 10);
    }
    if (this.devices.length > 0) {
      this.device = this.devices.find(device => device.id === this.id);
    } else {
      this.fetch();
      store.subscribe((setDevices: { payload: IStateDevice[] }) => {
        if (setDevices.payload instanceof Array) {
         this.device = this.devices.find(device => device.id === this.id);
       }
      });
    }
  }
  public submit() {
    if (this.device) {
      this.delete(this.device);
      router.push('/devices');
    }
  }
  public close() {
    router.push('/devices');
  }
}

export default DeleteDevice;
