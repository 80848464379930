import axios from 'axios';

import { Resolve } from './';
import config from './config';

const url = process.env.VUE_APP_SERVER_PATH;

export const getHeadCounterEmails = () =>
  new Promise((resolve: Resolve<{ file: string }>, reject) => {
    axios
      .get(`${url}/v2/hc-subscriptions/export`, config())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
