import { IDevicesTranslations } from '..';
import common from '../common/es';
const devices: IDevicesTranslations = {
  ...common,
  new_device: 'New device',
  assign: 'Assign',
  edit_device: 'Edit device',
  assign_devices: 'Assign devices',
  assign_devices_event: 'Assign devices to event',
  update_devices: 'Update devices',
  update_devices_model: 'Update devices model',
  update_device_status: 'Update device status',
  delete_device: 'Delete device',
  delete_device_confirm: 'Are you sure you want to delete this device?',
  delete_devices: 'Delete devices',
  delete_devices_confirm: 'Are you sure you want to delete these devices?',
  export_devices: 'Export devices',
  export_devices_confirm: 'You will receive an .xlsx file with all your devices.',
  import_devices: 'Import devices',
  receive_devices_event: 'Receive devices from event',
  receive_devices: 'Receive devices',
  change_base: 'Change base',
  change_devices_base: "Change device's base",
  receive: 'Receive',
  change_status: 'Change status',
  change_devices_model: 'Change devices model',
  categories: 'Categories',
  models: 'Models',
  export: 'Export',
  import: 'Import',
  model: 'Model',
  category: 'Category',
  serial_id: 'Serial ID',
  serial_id_placeholder: 'Enter device serial ID',
  mac_address: 'Mac address',
  mac_address_placeholder: 'Enter device mac address',
  status: 'Status',
  in_use: 'In Use',
  available: 'Available',
  repairing: 'Repairing',
  receiving: 'Receiving',
  missing: 'Missing',
  firmware_version: 'Firmware version',
  firmware_version_placeholder: 'Enter device firmware version',
  data_version: 'Data version',
  data_version_placeholder: 'Enter device data version',
  comment: 'Comment',
  comment_placeholder: 'Enter a comment about this device',
  created_at: 'Created at',
  updated_at: 'Updated at',
  current_event_name: 'Current event name',
  current_exhibitor_name: 'Current exhibitor name',
  key_tag: 'Key tag',
  base: 'Base',
  create_success: 'Device created.',
  edit_success: 'Device edited.',
  assign_success: 'Device assigned to event.',
  location_success: 'Devices location set.',
  model_success: 'Devices model set.',
  status_success: 'Devices status set.',
  delete_success: 'Device deleted.',
  deletes_success: 'Devices deleted.'
};

export default devices;
