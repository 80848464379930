import { IDevicesTranslations } from '..';
import common from '../common/pt';

const devices: IDevicesTranslations = {
  ...common,
  new_device: 'Novo dispositivo',
  edit_device: 'Editar dispositivo',
  assign_devices: 'Atribuir dispositivo',
  assign_devices_event: 'Atribuir dispositivos a evento',
  update_devices: 'Atualizar dispositivos',
  update_devices_model: 'Atualizar modelo de dispositivos',
  update_device_status: 'Atualizar estado de dispositivos',
  delete_device: 'Eliminar dispositivo',
  delete_device_confirm: 'Tem a certeza que quer eliminar este dispositivo?',
  delete_devices: 'Eliminar dispositivos',
  delete_devices_confirm: 'Tem a certeza que quer eliminar estes dispositivos?',
  export_devices: 'Exportar dispositivos',
  export_devices_confirm: 'Vai receber um ficheiro .xlsx com todos os dispositivos.',
  import_devices: 'Importar dispositivos',
  receive_devices_event: 'Receber dispositivos de evento',
  receive_devices: 'Receber dispositivos',
  change_devices_base: 'Mudar base de dispositivo',
  change_base: 'Change base',
  assign: 'Atribuir',
  receive: 'Receber',
  change_status: 'Alterar estado',
  change_devices_model: 'Alterar modelo de dispositivo',
  categories: 'Categorias',
  models: 'Modelos',
  export: 'Exportar',
  import: 'Importar',
  create: 'Criar',
  email: 'Email',
  name: 'Nome',
  model: 'Modelo',
  category: 'Categoria',
  serial_id: 'ID de serie',
  serial_id_placeholder: 'Digite o ID de serie',
  mac_address: 'Mac address',
  mac_address_placeholder: 'Digite o mac address',
  status: 'Estado',
  in_use: 'Em uso',
  available: 'Disponível',
  repairing: 'A reparar',
  receiving: 'A receber',
  missing: 'Desaparecido',
  firmware_version: 'Versão de firmware',
  firmware_version_placeholder: 'Digite a versão de firmware',
  data_version: 'Versão de dados',
  data_version_placeholder: 'Digite a versão de dados',
  comment: 'Commentário',
  comment_placeholder: 'Digite um comentário sobre este dispositivo',
  created_at: 'Criado em',
  updated_at: 'Atualizado em',
  current_event_name: 'Nome de evento atual',
  current_exhibitor_name: 'Nome de exibitor atual',
  key_tag: 'Etiqueta chave',
  base: 'Base',
  create_success: 'Dispositivo criado.',
  edit_success: 'Dispositivo editado.',
  assign_success: 'Dispositivo atribuido a evento.',
  location_success: 'Localização de dispositivo atribuida.',
  model_success: 'Modelo de dispositivo atribuido.',
  status_success: 'Estado de dispositivo definido.',
  delete_success: 'Dispositivo eliminado.',
  deletes_success: 'Dispositivos eliminados'
};

export default devices;
