import { IDeviceCategoriesTranslations } from '..';
import common from '../common/es';

const deviceCategories: IDeviceCategoriesTranslations = {
  ...common,
  device_categories: 'Device categories',
  edit_device_category: 'Edit device category',
  delete_device_categories: 'Delete device categories',
  deletes_confirm: 'Are you sure you want to delete these device categories?',
  new_device_category: 'Nova device category',
  device_category_name: 'Device category name',
  device_category_name_placeholder: 'Enter device category name',
  device_category_description: 'Device category description',
  device_category_description_placeholder: 'Enter device category description',
};

export default deviceCategories;
