




























































// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';

import { IInput } from './';

@Component({
  name: 'Input'
})
class Input extends Vue {
  @Prop({ required: true }) public config!: IInput;
  @Prop({ required: true }) public value!: string | number;
  public fileName = '';
  public created() {}
  public updateValue(value: string | number) {
    this.$emit('input', value);
    this.config.error = false;
    if (this.config.onInput) {
      this.config.onInput(value);
    }
  } public updateFile($input: HTMLInputElement) {
    if ($input.files) {
      this.fileName = $input.files[0].name;
      this.$emit('input', $input.files[0]);
    }
  }
}
export default Input;
