











































































// @ is an alias to /src
import {
  addEventManager,
  deleteEventManager,
  getEventManagers
} from '@/api/events';
import { createUser, fetchUserByEmail, fetchUserById } from '@/api/users';
import { IModal } from '@/components';
import { IInput, ISwitch } from '@/components/form';
import Input from '@/components/form/input.vue';
import Toggle from '@/components/form/switch.vue';
import Modal from '@/components/modal.vue';
import { IEventManager } from '@/models/event-manager';
import { IUser } from '@/models/user';
import router from '@/router';
import store from '@/store';
import { IStateEvent } from '@/store/modules/events';
import { IEventsTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import { eventActionPopup, eventsModal } from '../';

interface IUserManager extends IEventManager {
  id: number;
  user: IUser;
}

@Component({
  name: 'AddEventManager',
  components: { Modal, Input, Toggle }
})
class AddEventManager extends Vue {
  @Getter('getEvents') public events!: IStateEvent[];

  @Action('fetchEvents') public fetch!: () => void;

  @Getter('getSelectedEvents') public selected!: number[];

  @Getter('getEventsTranslations') public translations!: IEventsTranslations;
  public eventManagers: IUserManager[] = [];
  public modal = eventsModal;
  public loadingAssign = false;
  public loadingCreate = false;
  public userNotExists = false;
  public setupUser = false;
  public email = '';
  public name = '';
  public password = '';
  public eventActionPopup = eventActionPopup;
  public id = 0;
  public event!: IStateEvent;
  public inputEmail = {} as IInput;
  public switchToggle = {} as ISwitch;
  public inputName = {} as IInput;
  public inputPassword = {} as IInput;
  public created() {
    this.inputEmail = {
      id: 'user-input',
      label: this.translations.email,
      type: 'text',
      placeholder: this.translations.manager_email_placeholder,
      icon: 'person',
      onInput: this.clearUserError.bind(this),
      value: ''
    };

    this.switchToggle = {
      id: 'setup-user-toggle',
      label: this.translations.manager_setup,
      value: false
    };

    this.inputName = {
      id: 'setup-user-name',
      label: this.translations.name,
      placeholder: this.translations.manager_user_name_placeholder,
      type: 'text',
      value: ''
    };

    this.inputPassword = {
      id: 'setup-user-password',
      label: this.translations.manager_user_password,
      placeholder: this.translations.manager_user_password_placeholder,
      type: 'password',
      value: ''
    };

    if (this.selected.length === 1) {
      this.id = this.selected[0];
      if (this.events.length > 0) {
        this.findAndAssign();
        if (this.event.id) {
         this.findEventManagers();
       }
      } else {
        this.fetch();
      }
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setEvents') {
         this.findAndAssign();
         if (this.event.id) {
            this.findEventManagers();
          }
       }
      });
    }
  } public createAndAssign() {
    let formValid = true;

    const newUser: IUser = {
      email: this.email,
      name: this.name,
      password: this.password,
      event: this.event.id
    };
    if (this.setupUser) {
      if (this.name === '') {
       this.inputName = {
          ...this.inputName,
          error: true,
          message: 'User name is required'
        };
       formValid = false;
     }
      if (this.password === '') {
       this.inputPassword = {
          ...this.inputPassword,
          error: true,
          message: 'User password is required'
        };

       formValid = false;
     }
      if (!formValid) {
       return false;
     }
    } else {
      delete newUser.name;
      delete newUser.password;
    }

    this.loadingCreate = true;

    createUser(newUser)
      .then(response => {
        if (response.data && response.data.id) {
          const newEventManager = {
            user_account: response.data.id,
            event: this.event.id
          };

          addEventManager(newEventManager)
            .then(response => {
              this.eventManagers.push({
                ...response.data,
                user: {
                  name: newUser.name,
                  email: newUser.email,
                  id: response.data.user_account
                }
              });

              this.eventActionPopup.isActive = true;
              this.eventActionPopup.message = this.translations.manager_added_success;
              this.eventActionPopup.type = 'success';

              this.inputEmail = {
                ...this.inputEmail,
                value: '',
                error: false
              };

              this.loadingCreate = false;
              this.userNotExists = false;

              setTimeout(() => {
                this.eventActionPopup.isActive = false;
              }, 5000);
            })
            .catch(err => {
              this.inputEmail = {
                ...this.inputEmail,
                error: true,
                message: err.message
              };
              this.loadingCreate = false;
            });
        }
      })
      .catch(err => {
        this.inputEmail = {
          ...this.inputEmail,
          value: this.email,
          error: true,
          message: err.message
        };

        this.loadingCreate = false;
      });
  }
  public clearUserError() {
    this.userNotExists = false;
  }
  public deleteEventManager(id: number) {
    deleteEventManager(id)
      .then(response => {
        this.eventManagers = this.eventManagers.filter(
          eventManager => eventManager.id !== id
        );

        this.eventActionPopup.type = 'success';
        this.eventActionPopup.isActive = true;
        this.eventActionPopup.message = this.translations.manager_deleted_success;
        this.eventActionPopup.type = 'success';
        setTimeout(() => {
          this.eventActionPopup.isActive = false;
        }, 5000);
      })
      .catch(err => {
        this.eventActionPopup.type = 'success';
        this.eventActionPopup.isActive = true;
        this.eventActionPopup.message = err.message;
        this.eventActionPopup.type = 'danger';
        setTimeout(() => {
          this.eventActionPopup.isActive = false;
        }, 5000);
      });
  }
  public async findEventManagers() {
    const response = await getEventManagers(this.event.id);
    const eventManagers = response.data.results;
    if (eventManagers.length > 0) {
      eventManagers.forEach(async (eventManager: IEventManager) => {
        const response = await fetchUserById(eventManager.user_account);
        this.eventManagers.push({
         ...eventManager,
         user: response.data,
         event: this.event.id
       });
      });
    }
  }
  public findAndAssign() {
    const event = this.events.find(event => event.id === this.id);
    if (event) {
      this.event = event;
    }
  }
  public submit() {
    if (this.event && this.email !== '') {
      this.loadingAssign = true;

      fetchUserByEmail(this.email)
        .then(response => {
          const user = response.data.results[0];
          const userId = user.id;

          if (this.email) {
            const eventManager = {
              user_account: userId ? userId : 0,
              event: this.event.id
            };

            addEventManager(eventManager)
              .then(response => {
                this.eventManagers.push({
                  ...response.data,
                  user,
                  event: this.event.id
                });

                this.eventActionPopup.isActive = true;
                this.eventActionPopup.message = this.translations.manager_added_success;
                this.eventActionPopup.type = 'success';
                setTimeout(() => {
                  this.eventActionPopup.isActive = false;
                }, 5000);
                this.loadingAssign = false;
              })
              .catch(err => {
                this.inputEmail = {
                  ...this.inputEmail,
                  error: true,
                  message: err.message
                };
                this.loadingAssign = false;
              });
          }
        })
        .catch(err => {
          this.inputEmail = {
            ...this.inputEmail,
            value: this.email,
            error: true,
            message: this.translations.manager_user_not_exists
          };

          this.userNotExists = true;
          this.loadingAssign = false;
        });
    }
  }
  public close() {
    router.push('/events');
  }
}
export default AddEventManager;
