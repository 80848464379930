






















// @ is an alias to /src
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Textarea from '@/components/form/textarea.vue';
import Offcanvas from '@/components/offcanvas.vue';
import { IDevice } from '@/models/device';
import router from '@/router';
import store from '@/store';
import { IStateDevice } from '@/store/modules/devices';
import { IDevicesTranslations } from '@/translations';
import modelToForm from '@/utils/modelToForm';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import { deviceForm, deviceModel, deviceOffcanvas } from './../';

@Component({
  name: 'EditDevice',
  components: { Offcanvas, Input, Textarea, Select }
})
class EditDevice extends Vue {
  @Action('fetchDevices') public fetch!: () => void;

  @Action('editDevice') public edit!: (device: IStateDevice) => void;

  @Getter('getDevices') public devices!: IStateDevice[];

  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public offcanvas = deviceOffcanvas;

  /*
    Spread operator used to prevent values to propagate to other views using
    the same form data
  */ public form = { ...deviceForm }; public device = { ...deviceModel };
  public id = 0;
  public created() {
    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id, 10);
      if (this.devices.length > 0) {
        this.findAndAssign();
      } else {
        this.fetch();
        const unsubscribe = store.subscribe(
          (setDevices: { payload: IStateDevice[] }) => {
            if (setDevices.payload instanceof Array) {
              this.devices = setDevices.payload;
              this.findAndAssign();
              unsubscribe();
            }
          }
        );
      }
    }
  }
  public findAndAssign() {
    const device = this.devices.find(device => device.id === this.id);
    if (device) {
      this.device = { ...device };
      this.form = modelToForm(this.device, this.form);
    }
  }
  public submit() {
    if (this.device) {
      this.edit(this.device);
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setDevicesError') {
         const errors = mutation.payload;
         const errorKeys = Object.keys(errors) as Array<
            Extract<IDevice, string>
          >;
         const form = {} as any;

         errorKeys.forEach(key => {
            form[key] = {
              ...(this.form[key] as object),
              error: true,
              message: errors[key][0]
            };
          });

         this.form = { ...this.form, ...form };
       }

        if (mutation.type === 'editDevice') {
         router.push('/devices');
         unsubscribe();
       }
      });
    }
  }
  public discard() {
    router.push('/devices');
  }
}

export default EditDevice;
