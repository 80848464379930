


















// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ICheckbox } from './';

@Component({
  name: 'Checkbox'
})
class Checkbox extends Vue {
  @Prop({ required: true }) public config!: ICheckbox;
  public updateValue(value: boolean) {
    this.config.value = !this.config.value;
    this.$emit('input', this.config.value);
    if (this.config.onClick) {
      this.config.onClick();
    }
  }
}
export default Checkbox;
