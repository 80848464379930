





















import Scrollable from '@/components/scrollable.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { IOffcanvas } from './';

@Component({
  name: 'Offcanvas',
  components: { Scrollable }
})
class Offcanvas extends Vue {
  @Prop({ required: true }) public config!: IOffcanvas;
  public created() {}
  public close() {
    if (this.config.close) {
      this.config.close();
    } else {
      if (this.config.onClose) {
        this.config.onClose();
      }
      this.config.isActive = false;
    }
  }
}

export default Offcanvas;
