

















// @ is an alias to /src
import Input from '@/components/form/input.vue';
import Textarea from '@/components/form/textarea.vue';
import Offcanvas from '@/components/offcanvas.vue';
import router from '@/router';
import store from '@/store';
import { IStateDeviceModel } from '@/store/modules/deviceModels';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { IDeviceModelsTranslations } from '../../../translations';

import {
  deviceModel,
  deviceModelOffcanvas,
  form,
  IDeviceModelForm
} from './../DeviceModels.data';

@Component({
  name: 'AddDevice',
  components: { Input, Textarea, Offcanvas }
})
class AddDevice extends Vue {
  @Action('addDeviceModel') public add!: (deviceModel: IStateDeviceModel) => void;

  @Getter('getDeviceModelsTranslations') public translations!: IDeviceModelsTranslations;
  public offcanvas = deviceModelOffcanvas;
  public form!: IDeviceModelForm;
  public deviceModel = deviceModel;
  public created() {
    this.form = form(this.translations);

    const keys = Object.keys(this.deviceModel) as Array<
      Extract<IStateDeviceModel, string>
    >;
    keys.forEach(key => {
      this.deviceModel[key] === '';
    });
  }
  public submit() {
    this.add(this.deviceModel);
    router.push('/devices/models');
  }
  public discard() {
    router.push('/devices/models');
  }
}

export default AddDevice;
