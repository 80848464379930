














// @ is an alias to /src
import { IDeviceModelsCategory } from '@/api/deviceModels';
import { ISelect } from '@/components/form';
import Select from '@/components/form/select.vue';
import Modal from '@/components/modal.vue';
import { IDeviceCategory } from '@/models/deviceCategory';
import router from '@/router';
import store from '@/store';
import { IStateDeviceCategory } from '@/store/modules/deviceCategories';
import { IStateDevice } from '@/store/modules/devices';
import { IDeviceModelsTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import { deviceModelModal } from './../DeviceModels.data';

@Component({
  name: 'ChangeDevicesCategory',
  components: { Modal, Select }
})
class ChangeDevicesCategory extends Vue {
  @Action('fetchDeviceCategories') public fetch!: () => void;

  @Action('changeDeviceModelsCategory') public changeCategory!: (devices: IDeviceModelsCategory) => void;

  @Getter('getDeviceCategories') public deviceCategories!: IDeviceCategory[];

  @Getter('getSelectedDeviceModels') public selectedDeviceModels!: number[];

  @Getter('getDeviceModelsTranslations') public translations!: IDeviceModelsTranslations;
  public categoryId = '';
  public modal = deviceModelModal;
  public selectCategory: ISelect = {
    id: 'select-device-category',
    default: 'Select device model category',
    value: '',
    options: []
  };
  public id = 0;
  public created() {
    if (this.deviceCategories.length > 0) {
      this.mapEventsToSelectOptions();
    } else {
      this.fetch();
      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setDeviceCategories') {
         this.mapEventsToSelectOptions();
       }
      });
    }
  }
  public mapEventsToSelectOptions() {
    this.selectCategory = {
      ...this.selectCategory,
      options: this.deviceCategories.map(deviceCategory => ({
        value: deviceCategory.id ? deviceCategory.id : 0,
        label: deviceCategory.name
      }))
    };
  }
  public submit() {
    if (this.categoryId !== '') {
      this.changeCategory({
        ids: this.selectedDeviceModels,
        category_id: parseInt(this.categoryId, 10)
      });
    }
    router.push('/devices/models');
  }
  public close() {
    router.push('/devices/models');
  }
}

export default ChangeDevicesCategory;
