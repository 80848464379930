import Vue from 'vue';
import Vuex from 'vuex';

import auth, { IAuthState } from './modules/auth';
import deviceCategories, { IDeviceCategoriesState } from './modules/deviceCategories';
import deviceModels, { IDeviceModelsState } from './modules/deviceModels';
import devices, { IDevicesState } from './modules/devices';
import devicesWizard, { IDevicesWizardState } from './modules/devicesWizard';
import events, { IEventsState } from './modules/events';
import spaces, { ISpacesState } from './modules/spaces';
import translations, { ITranslationsState } from './modules/translations';
import users, { IUsersState } from './modules/users';

Vue.use(Vuex);

/* Typescript coverage very superficial, only to exemplify implementation.
*/

export interface IState {
  users: IUsersState;
  events: IEventsState;
  devices: IDevicesState;
  deviceModels: IDeviceModelsState;
  deviceCategories: IDeviceCategoriesState;
  devicesWizard: IDevicesWizardState;
  auth: IAuthState;
  translations: ITranslationsState;
  spaces: ISpacesState;
}

export interface IObjectState {
  loading: boolean;
  error: boolean;
  message: string;
}

export interface IStateObject<T> extends IObjectState {
  list: T[];
  selected: number[];
  pages?: number;
}

export default new Vuex.Store({
  state: {} as IState,
  mutations: {},
  actions: {},
  modules: {
    users,
    events,
    devices,
    deviceModels,
    deviceCategories,
    devicesWizard,
    auth,
    translations,
    spaces
  }
});
