import { IUsersTranslations } from '../';

const users: IUsersTranslations = {
  name: 'Nombre',
  users: 'Usuarios',
  email: 'Email',
  phone: 'Número de teléfono',
  deleteUsers: 'Eliminar Usuarios',
  delete_users_confirm: '¿Seguro que quieres eliminar los usuarios seleccionados? Esta acción no se puede deshacer.',
};

export default users;
