

















// @ is an alias to /src
import { IOffcanvas } from '@/components';
import { IInput } from '@/components/form';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Textarea from '@/components/form/textarea.vue';
import Offcanvas from '@/components/offcanvas.vue';
import router from '@/router';
import store from '@/store';
import { IStateDeviceCategory } from '@/store/modules/deviceCategories';
import { IDeviceCategoriesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import {
  deviceCategory,
  deviceCategoryOffcanvas,
  form,
  IDeviceCategoryForm
} from './../DeviceCategories.data';

@Component({
  name: 'AddDevice',
  components: { Offcanvas, Input, Select, Textarea }
})
class AddDevice extends Vue {
  @Action('addDeviceCategory') public add!: (deviceCategory: IStateDeviceCategory) => void;

  @Getter('getDeviceCategoriesTranslations') public translations!: IDeviceCategoriesTranslations;
  public offcanvas = deviceCategoryOffcanvas;
  public form!: IDeviceCategoryForm; public deviceCategory = deviceCategory;
  public created() {
    this.form = form(this.translations);
  }
  public submit() {
    this.add(this.deviceCategory);
    router.push('/devices/categories');
  }
  public discard() {
    router.push('/devices/categories');
  }
}

export default AddDevice;
