
import AuthEN from './auth/en';
import AuthES from './auth/es';
import AuthPT from './auth/pt';
import CommonEN from './common/en';
import CommonES from './common/es';
import CommonPT from './common/pt';
import DashboardEN from './dashboard/en';
import DashboardES from './dashboard/es';
import DashboardPT from './dashboard/pt';
import DeviceCategoriesEN from './deviceCategories/en';
import DeviceCategoriesES from './deviceCategories/es';
import DeviceCategoriesPT from './deviceCategories/pt';
import DeviceModelsEN from './deviceModels/en';
import DeviceModelsES from './deviceModels/es';
import DeviceModelsPT from './deviceModels/pt';
import DevicesEN from './devices/en';
import DevicesES from './devices/es';
import DevicesPT from './devices/pt';
import EventsEN from './events/en';
import EventsES from './events/es';
import EventsPT from './events/pt';
import SpacesEN from './spaces/en';
import SpacesES from './spaces/es';
import SpacesPT from './spaces/pt';
import TableEN from './table/en';
import TableES from './table/es';
import TablePT from './table/pt';
import UsersEN from './users/en';
import UsersES from './users/es';
import UsersPT from './users/pt';

export interface ICommonTranslations {
  dashboard: string;
  events: string;
  devices: string;
  logout: string;
  create: string;
  edit: string;
  delete: string;
  name: string;
  email: string;
  description: string;
  category: string;
  model: string;
  save: string;
  download: string;
  upload: string;
  change: string;
  apply: string;
  enabled: string;
  disabled: string;
  users: string;
}

export interface ISpacesTranslations {
  deleteSpaces: string;
  spaces: string;
  name: string;
  currentCount: string;
  capacity: string;
  slug: string;
  website: string;
  facebook: string;
  phone: string;
  delete_spaces_confirm: string;
  user: string;
  updated_at: string;
}

export interface IUsersTranslations {
  users: string;
  name: string;
  email: string;
  phone: string;
  deleteUsers: string;
  delete_users_confirm: string;
}

export interface IAuthTranslations {
  welcome: string;
  email: string;
  email_placeholder: string;
  password: string;
  password_placeholder: string;
  login: string;
  log_back: string;
  logged_out: string;
  or: string;
  other_account: string;
}

export interface IDashboardTranslations extends ICommonTranslations {
  welcome: string;
  whatsup: string;
  create_event: string;
  create_event_description: string;
  create_device: string;
  create_device_description: string;
  events: string;
  manage_events: string;
  devices: string;
  manage_devices: string;
  categories: string;
  manage_categories: string;
  models: string;
  manage_models: string;
  download_subscribed_emails: string;
}

export interface IDevicesTranslations extends ICommonTranslations {
  new_device: string;
  edit_device: string;
  assign_devices: string;
  assign_devices_event: string;
  update_devices: string;
  update_devices_model: string;
  update_device_status: string;
  delete_device: string;
  delete_device_confirm: string;
  delete_devices: string;
  delete_devices_confirm: string;
  export_devices: string;
  export_devices_confirm: string;
  import_devices: string;
  assign: string;
  receive: string;
  receive_devices_event: string;
  receive_devices: string;
  change_base: string;
  change_devices_base: string;
  change_status: string;
  change_devices_model: string;
  categories: string;
  models: string;
  export: string;
  import: string;
  model: string;
  category: string;
  serial_id: string;
  serial_id_placeholder: string;
  mac_address: string;
  mac_address_placeholder: string;
  status: string;
  in_use: string;
  available: string;
  repairing: string;
  receiving: string;
  missing: string;
  firmware_version: string;
  firmware_version_placeholder: string;
  data_version: string;
  data_version_placeholder: string;
  comment: string;
  comment_placeholder: string;
  created_at: string;
  updated_at: string;
  current_event_name: string;
  current_exhibitor_name: string;
  key_tag: string;
  base: string;
  create_success: string;
  edit_success: string;
  assign_success: string;
  location_success: string;
  model_success: string;
  status_success: string;
  delete_success: string;
  deletes_success: string;
}

export interface IDeviceModelsTranslations extends ICommonTranslations {
  device_models: string;
  change_model: string;
  edit_device_model: string;
  delete_device_models: string;
  deletes_confirm: string;
  new_device_model: string;
  change_category: string;
  device_model_name: string;
  device_model_name_placeholder: string;
  device_model_description: string;
  device_model_description_placeholder: string;
}
export interface IDeviceCategoriesTranslations extends ICommonTranslations {
  device_categories: string;
  edit_device_category: string;
  delete_device_categories: string;
  deletes_confirm: string;
  new_device_category: string;
  device_category_name: string;
  device_category_name_placeholder: string;
  device_category_description: string;
  device_category_description_placeholder: string;
}

export interface IEventsTranslations extends ICommonTranslations {
  new: string;
  edit: string;
  send_registration_invite: string;
  name_placeholder: string;
  assign: string;
  delete_manager: string;
  no_managers_assigned: string;
  start_date: string;
  end_date: string;
  capacity: string;
  location: string;
  location_placeholder: string;
  is_published: string;
  base_cost: string;
  max_capacity: string;
  assign_manager: string;
  manager: string;
  select_manager: string;
  timezone: string;
  europe: string;
  london: string;
  lisbon: string;
  madrid: string;
  berlin: string;
  default_language: string;
  portuguese: string;
  spanish: string;
  english: string;
  german: string;
  french: string;
  description: string;
  description_placeholder: string;
  image: string;
  image_placeholder: string;
  background_image: string;
  background_image_placeholder: string;
  managers: string;
  manager_email_placeholder: string;
  created_success: string;
  manager_added_success: string;
  manager_deleted_success: string;
  manager_user_not_exists: string;
  manager_setup: string;
  manager_user_password: string;
  manager_user_name_placeholder: string;
  manager_user_password_placeholder: string;
  booklet: string;
  setup_booklet: string;
  title: string;
  title_placeholder: string;
  cover: string;
  cover_placeholder: string;
  configure_booklet: string;
  no_fields_selected: string;
  drag_drop_fields: string;
  fields: string;
  create_booklet_success: string;
  create_booklet_error: string;
  edit_booklet_success: string;
  edit_booklet_error: string;
  no_available_fields: string;
  empty_space: string;
  live_interactions_email: string;
}

export interface ITableTranslations {
  showing: string;
  of: string;
  items: string;
  see: string;
  see_selected_only: string;
  clear_selection: string;
  all_items: string;
  items_per_page: string;
  previous: string;
  next: string;
  table_settings: string;
  refresh_table: string;
  show_hide_columns: string;
  search_settings: string;
  search_fields: string;
  search_data: string;
}

export default {
  pt: {
    events: EventsPT,
    devices: DevicesPT,
    deviceCategories: DeviceCategoriesPT,
    deviceModels: DeviceModelsPT,
    table: TablePT,
    dashboard: DashboardPT,
    common: CommonPT,
    auth: AuthPT,
    spaces: SpacesPT,
    users: UsersPT,
  },
  en: {
    events: EventsEN,
    devices: DevicesEN,
    deviceCategories: DeviceCategoriesEN,
    deviceModels: DeviceModelsEN,
    table: TableEN,
    dashboard: DashboardEN,
    common: CommonEN,
    auth: AuthEN,
    spaces: SpacesEN,
    users: UsersEN,
  },
  es: {
    events: EventsES,
    devices: DevicesES,
    deviceCategories: DeviceCategoriesES,
    deviceModels: DeviceModelsES,
    table: TableES,
    dashboard: DashboardES,
    common: CommonES,
    auth: AuthES,
    spaces: SpacesES,
    users: UsersES,
  }
};
