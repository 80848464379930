

















// @ is an alias to /src
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Textarea from '@/components/form/textarea.vue';
import Offcanvas from '@/components/offcanvas.vue';
import router from '@/router';
import store from '@/store';
import { IStateDeviceModel } from '@/store/modules/deviceModels';
import { IDeviceModelsTranslations } from '@/translations';
import modelToForm from '@/utils/modelToForm';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import {
  deviceModel,
  deviceModelOffcanvas,
  form,
  IDeviceModelForm
} from './../DeviceModels.data';

@Component({
  name: 'EditDevice',
  components: { Offcanvas, Input, Textarea, Select }
})
class EditDevice extends Vue {
  @Action('fetchDeviceModels') public fetch!: () => void;

  @Action('editDeviceModel') public edit!: (deviceModel: IStateDeviceModel) => void;

  @Getter('getDeviceModels') public deviceModels!: IStateDeviceModel[];

  @Getter('getDeviceModelsTranslations') public translations!: IDeviceModelsTranslations;
  public offcanvas = deviceModelOffcanvas;

  /*
    Spread operator used to prevent values to propagate to other views using
    the same form data
  */ public form!: IDeviceModelForm;
  public deviceModel = { ...deviceModel };
  public id = 0;
  public created() {
    this.form = form(this.translations);

    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id, 10);
      if (this.deviceModels.length > 0) {
        this.findAndAssign();
      } else {
        this.fetch();
        const unsubscribe = store.subscribe((mutation: MutationPayload) => {
         if ((mutation.type === 'setDevices')) {
            this.findAndAssign();
            unsubscribe();
          }
       });
      }
    }
  }
  public findAndAssign() {
    const deviceModel = this.deviceModels.find(device => device.id === this.id);
    if (deviceModel) {
      this.deviceModel = deviceModel;
      this.form = modelToForm(this.deviceModel, this.form);
    }
  }
  public submit() {
    if (this.deviceModel) {
      this.edit(this.deviceModel);
      router.push('/devices/models');
    }
  }
  public discard() {
    router.push('/devices/models');
  }
}

export default EditDevice;
