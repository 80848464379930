import { IDeviceCategoriesTranslations } from '..';
import common from '../common/pt';

const deviceCategories: IDeviceCategoriesTranslations = {
  ...common,
  device_categories: 'Categorias de dispositivo',
  edit_device_category: 'Editar categoria de dispositivo',
  delete_device_categories: 'Eliminar categorias de dispositivo',
  deletes_confirm: 'Tem a certeza que quer eliminar estas categorias de dispositivo?',
  new_device_category: 'Nova categoria de dispositivo',
  device_category_name: 'Nome da categoria de dispositivo',
  device_category_name_placeholder: 'Digite o nome da categoria de dispositivo',
  device_category_description: 'Descrição da categoria de dispositivo',
  device_category_description_placeholder: 'Digite uma descrição para a categoria de dispositivo',
};

export default deviceCategories;
