
















// @ is an alias to /src
import Modal from '@/components/modal.vue';
import router from '@/router';
import store from '@/store';
import { IStateDeviceModel } from '@/store/modules/deviceModels';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter } from 'vuex-class';

import { deviceModelModal } from './../DeviceModels.data';

@Component({
  name: 'DeleteDeviceModel',
  components: { Modal }
})
class DeleteDeviceModel extends Vue {
  @Action('fetchDeviceModels') public fetch!: () => void;

  @Getter('getDeviceModels') public deviceModels!: IStateDeviceModel[];

  @Action('deleteDeviceModel') public delete!: (device: IStateDeviceModel) => void;
  public modal = deviceModelModal;
  public deviceModel?: IStateDeviceModel;
  public id = 0;
  public created() {
    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id, 10);
    }
    if (this.deviceModels.length > 0) {
      this.deviceModel = this.deviceModels.find(
        deviceModel => deviceModel.id === this.id
      );
    } else {
      this.fetch();
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setDevices') {
         this.deviceModel = this.deviceModels.find(
            deviceModel => deviceModel.id === this.id
          );
         unsubscribe();
       }
      });
    }
  }
  public submit() {
    if (this.deviceModel) {
      this.delete(this.deviceModel);
      router.push('/devices/models');
    }
  }
  public close() {
    router.push('/devices/models');
  }
}

export default DeleteDeviceModel;
