

















// @ is an alias to /src
import { ISelect } from '@/components/form';
import Select from '@/components/form/select.vue';
import Modal from '@/components/modal.vue';
import router from '@/router';
import store from '@/store';
import { IDevicesTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { deviceModal } from '../';

@Component({
  name: 'DeleteDevices',
  components: { Modal, Select }
})
class DeleteDevices extends Vue {
  @Action('deleteDevices') public delete!: (ids: number[]) => void;

  @Getter('getSelectedDevices') public selectedDevices!: number[];

  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public base = '';
  public modal = deviceModal;
  public created() {}
  public submit() {
    this.delete(this.selectedDevices);
    router.push('/devices');
  }
  public close() {
    router.push('/devices');
  }
}

export default DeleteDevices;
