
<script>
// @ is an alias to /src
import { Doughnut, mixins } from 'vue-chartjs'

export default {
  name: 'Doughnut',
  extends: Doughnut,
  data: () => ({
    chartData: {
      type: 'doughnut',
      datasets: [{
        data: [10, 20, 30],
        backgroundColor: [
          '#00B283',
          '#F9F871',
          '#ff3781'
        ],
        borderColor: '#222B45'
      }],

      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        'Available',
        'In use',
        'Broken'
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      color: [
        'red',    // color for data at index 0
        'blue',   // color for data at index 1
        'green',  // color for data at index 2
        'black',  // color for data at index 3
      ]
    }
  }),
  mounted() {
    this.renderChart(this.chartData, this.options);
  },

}
</script>
<style lang="scss">
</style>
