import { addSpace, editSpace, getSpaces } from '@/api/spaces';
import { ISpace } from '@/models/space';
import store from '@/store';
import formatDate from '@/utils/formatDate';
import { ActionContext } from 'vuex';

import { IState } from '..';
import { IObjectState, IStateObject } from '../';
import { deleteSpace } from '../../api/spaces';
/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateSpace extends IObjectState, ISpace {

}

export interface ISpacesState extends IStateObject<IStateSpace> {
  pages?: number;
}

const state: ISpacesState = {
  list: [],
  selected: [],
  loading: false,
  message: '',
  error: false,
  pages: 0
};

const addStateProps = (spaces: ISpace[]) =>
  spaces.map(space => ({
    ...space,
    loading: false,
    error: false,
    message: ''
  }));

const getters = {
  getSpaces: (state: ISpacesState) => state.list,
  getSpacesState: (state: ISpacesState) => state,
  getSelectedSpaces: (state: ISpacesState) => state.selected,
  getPages: (state: ISpacesState) => state.pages
};

const actions = {

  fetchSpaces(
    context: ActionContext<ISpacesState, IState>,
    params: { page: number, pageSize: number, search?: string }
  ) {
    context.commit('setSpacesLoading');

    getSpaces(params.page, params.pageSize, params.search)
      .then(response => {
        context.commit('setSpaces', addStateProps(response.data.results));
        context.commit('setPages', response.data.page_count);
      })
      .catch(err => {
        context.commit('setSpacesError', err.request);
      });

  },

  addSpace(context: ActionContext<ISpacesState, IState>, space: ISpace) {
    context.commit('setSpacesLoading');

    addSpace(space)
      .then(response => {
        context.commit('newSpace', addStateProps([response.data])[0]);

      })
      .catch(err => {
        context.commit('setSpacesError', err.response.data);
      });

  },

  editSpace(context: ActionContext<ISpacesState, IState>, editedSpace: IStateSpace) {
    context.commit('setLoadingSpace', context.state.list.find(space => editedSpace.id === space.id));

    editSpace(editedSpace)
      .then(response => {
        context.commit('editSpace', response);
      })
      .catch(err => {
        context.commit('setSpaceError', { failedSpace: editedSpace, message: err.message });
      });

  },
  deleteSpace(context: ActionContext<ISpacesState, IState>, deletedSpace: IStateSpace) {
    context.commit('setLoadingSpace', context.state.list.find(space => deletedSpace.id === space.id));

    deleteSpace(deletedSpace)
      .then(response => {
        context.commit('removeSpace', deletedSpace);
      })
      .catch(err => {
        context.commit('setSpaceError', { failedSpace: deletedSpace, message: err.message });
      });

  },

  deleteSpaces(context: ActionContext<ISpacesState, IState>, ids: number[]) {

    ids.forEach(id => {
      context.dispatch('deleteSpace', { id });
    });

    // axios
    //   .delete(`${url}/spaces/bulk_destroy?ids=${ids.join('&ids=')}`, config())
    //   .then(response => {
    //     context.commit('removeSpaces', ids);
    //   })
    //   .catch(err => {
    //     context.commit('setSpacesError', { ids, message: err.message });
    //   });
  },
};

const mutations = {

  setSpacesError: (state: ISpacesState, message: string) => (
    (state.loading = false),
    (state.error = true),
    (state.message = message)
  ),

  setSpaceError: (state: ISpacesState, { failedSpace, message }: { failedSpace: IStateSpace, message: string }) => (
    (state.list = state.list.map(space => space.id === failedSpace.id ? {
      ...space,
      loading: false,
      error: true,
      message
    } : space))
  ),

  setSpacesLoading: (state: ISpacesState) => (
    (state.loading = true),
    (state.error = false)
  ),

  setLoadingSpace: (state: ISpacesState, space: IStateSpace) => (
    (state.list[state.list.indexOf(space)] = {
      ...state.list[state.list.indexOf(space)],
      loading: true,
      error: false
    })
  ),

  setSpaces: (state: ISpacesState, spaces: IStateSpace[]) => (
    (state.error = false),
    (state.loading = false),
    (state.list = spaces)
  ),

  setPages: (state: ISpacesState, pages: number) => (
    (state.pages = pages)
  ),

  editSpace: (state: ISpacesState, editedSpace: IStateSpace) => (
    (state.list = state.list.map(
      space => space.id === editedSpace.id ? {
        ...editedSpace,
        loading: false,
        error: false
      } : space
    ))
  ),

  removeSpace: (state: ISpacesState, deletedSpace: ISpace) =>
    (
      state.list = state.list.filter(space => space.id !== deletedSpace.id)
    ),

  removeSpaces: (state: ISpacesState, ids: number[]) =>
    (
      state.list = state.list.filter(space => space.id && ids.indexOf(space.id) === -1)
    ),

  setLoadingSpaces: (state: ISpacesState, ids: number[]) => (
    (state.list = state.list.map(
      space => ({
        ...space,
        loading: space.id && ids.indexOf(space.id) > -1 ? true : false,
        error: false
      })
    ))
  ),
  selectSpace: (state: ISpacesState, space: IStateSpace) => {
    if (space.id) {
      const spaceIndex = state.selected.indexOf(space.id);
      if (spaceIndex > -1) {
        state.selected.splice(spaceIndex, 1);
      } else {
        state.selected.push(space.id);
      }
    }
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
